import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-instant-deposit',
  templateUrl: './instant-deposit.component.html',
  styleUrls: ['./instant-deposit.component.scss'],
})
export class InstantDepositComponent implements OnInit {
  @ViewChild('paymentForm') paymentForm: ElementRef;
  depositAmount: string;
  webRequestForm: FormGroup;
  errorText: string = '';
  cardRequestForm: FormGroup;
  webpaymentData: any;
  webData = [];
  PAY_REQUEST_ID = '';
  CHECKSUM = '';
  autoTopup: Boolean = false;
  public isCurrentRequest = false;

  constructor(
    private apiServices: ApiServicesService,
    private fb: FormBuilder,
    private generalService: GeneralService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.cardRequestForm = this.fb.group({
      PAY_REQUEST_ID: ['', Validators.required],
      CHECKSUM: ['', Validators.required],
    });
  }

  async nextClicked() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    const depositAmountValue = parseInt(this.depositAmount);
    if (depositAmountValue > 0 && depositAmountValue <= 200) {
      const paymentDetails = await this.apiServices.initiatePayment(
        this.depositAmount,
        this.autoTopup,
        'ip'
      );
      this.cardRequestForm.setValue({
        PAY_REQUEST_ID: [paymentDetails.paymentId],
        CHECKSUM: [paymentDetails.checksum],
      });
      this.paymentForm.nativeElement.submit();
    } else {
      this.errorText = 'Please enter a valid amount (R1 - R200)';
    }
    this.isCurrentRequest = false;
  }

  cancelClicked() {
    this.generalService.currentScreen = 'Deposit funds';
    this.generalService.currentChildScreen = 'Select option';
  }
}
