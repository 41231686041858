import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() screenHeight: number;
  public logoPath: string;
  public location: string;
  public currentYear: number;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.location = this.router.url;
    this.logoPath = '../../assets/logo.png';
    this.currentYear = new Date().getFullYear();
  }

  handleRouteClicked(route: string) {
    this.router.navigate(['/', route]);
  }
}
