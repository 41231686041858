import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParentDetails } from 'src/app/Models/ParentDetails';
import { ApiServicesService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-parent-details',
  templateUrl: './parent-details.component.html',
  styleUrls: ['./parent-details.component.scss'],
})
export class ParentDetailsComponent implements OnInit {
  @Input() parent: ParentDetails;
  @Input() isMobile: number;
  public parentDetail: FormGroup;
  public editName = false;
  public editSurname = false;
  public editEmail = false;
  public editPhoneNumber = false;
  public editPassword = false;
  public editBalanceNotificationAmount = false;

  constructor(
    private fb: FormBuilder,
    private apiServices: ApiServicesService
  ) {}

  ngOnInit(): void {
    this.parentDetail = this.fb.group({
      name: [this.parent.name, Validators.required],
      surname: [this.parent.surname, Validators.required],
      email: [this.parent.emailAddress, Validators.required],
      phoneNumber: [this.parent.cellphoneNumber, Validators.required],
      passowrd: [this.parent.password, Validators.required],
      isLowBalanceNotifications: [
        this.parent.lowBalanceNotifications,
        Validators.required,
      ],
      isAutoTopup: [this.parent.autoTopup, Validators.required],
      balanceNotificationAmount: [
        this.parent.balanceNotificationAmount,
        Validators.required,
      ],
    });
  }

  async saveParent() {
    this.parent.name = this.parentDetail.get('name').value;
    this.parent.surname = this.parentDetail.get('surname').value;
    this.parent.emailAddress = this.parentDetail.get('email').value;
    this.parent.cellphoneNumber = this.parentDetail.get('phoneNumber').value;
    this.parent.password = this.parentDetail.get('passowrd').value;
    this.parent.lowBalanceNotifications = this.parentDetail.get(
      'isLowBalanceNotifications'
    ).value;
    this.parent.autoTopup = this.parentDetail.get('isAutoTopup').value;
    this.parent.balanceNotificationAmount = parseInt(
      this.parentDetail.get('balanceNotificationAmount').value
    );

    const parentUpdateResult = await this.apiServices.updateParent(this.parent);
  }
}
