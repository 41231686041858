<div class="portal-container" [style.minHeight.px]="screenHeight - 220">
  <div class="portal-heading">Order items selection</div>
  <div class="portal-subheading">
    Select from the categories below
    <br />
  </div>
  <div class="header-icons-box">
    <div>
      <div class="checkout-button" (click)="cartClicked()">
        <mat-icon
          class="checkout-button-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >shopping_cart</mat-icon
        >
        <div class="checkout-button-item-count-circle">
          <div class="checkout-button-item-count-text">
            {{ selectedItemCount }}
          </div>
        </div>
      </div>
      <div class="portal-subheading">View cart</div>      
    </div>

    <div class="student-picture-box">
      <img
        class="student-small-picture"
        [src]="selectedStudent.picture"
        width="60"
      />
    </div>
  </div>
  <div class="categories-box">
    <div class="category-box" *ngFor="let category of categories">
      <div (click)="selectCategory(category)">
        <mat-icon
          class="category-icon"
          svgIcon="{{ category.iconName }}"
        ></mat-icon>
      </div>
      <div class="category-text">{{ category.description }}</div>
    </div>
  </div>
</div>
