import { Component, OnInit, HostListener } from '@angular/core';
import { ApiServicesService } from '../services/api-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  isMobile: boolean = false;

  public forgotPasswordForm: FormGroup;
  public isError = false;
  public isPasswordMatching = true;

  public isButtonLoading: boolean = false;
  public isResetComplete: boolean = false;
  public token: string;
  public error: string;

  @HostListener('window:resize', ['$event'])
  
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 700) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  constructor(
    private apiServices: ApiServicesService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute
    ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      passWord: ['', [Validators.required, Validators.minLength(8)]],
      passWordRepeat: ['', [Validators.required, Validators.minLength(8)]]
    });

    this.route.queryParams.subscribe(params => {
        this.token = params['token'];
        this.checkResetKey();
    });  
  }

  async checkResetKey() {
    const checkResetKeyResult = await this.apiServices.checkResetKey(this.token);

    if (checkResetKeyResult !== 'Ok') {
      this.error = checkResetKeyResult;
    }
  }

  async reset() {
    this.isButtonLoading = true;
    this.isPasswordMatching = true;

    if (this.forgotPasswordForm.get('passWord').value === this.forgotPasswordForm.get('passWordRepeat').value) {
      const payload = {
        password: this.forgotPasswordForm.get('passWord').value,
        resetKey: this.token
      };

      let resetResult = await this.apiServices.resetPassword(payload);

      if (resetResult == true) {
        this.isResetComplete = true;
      } else {
        this.isError = true;
      }
    } else {
      this.isPasswordMatching = false;
    }

    this.isButtonLoading = false;
  }

  loginNavigate() {
    this.router.navigate(['/', 'login']);
  }

  enterSubmit(event) {
    if (event.keyCode === 13) {
      this.reset();
    }
  }
}
