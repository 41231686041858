<div class="welcome-text">Welcome to your dashboard</div>
<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 280"
>
  <div class="portal-heading">Students</div>
  <div class="portal-subheading">
    Students that are linked to your<br />
    account
  </div>
  <div class="students-container">
    <div *ngFor="let student of students">
      <div
        (click)="selectStudentClick(student)"
        class="student-picture-parent clickable"
      >
        <img
          src="{{ student.picture }}"
          class="student-picture"
          draggable="false"
        />
        <div class="balance-box">
          <div class="balance-box-child">Balance: R{{ student.balance }}</div>
        </div>
      </div>
      <div class="student-name-text">{{ student.name }}</div>
      <div class="last-active-text" *ngIf="student?.lastUsed">
        <strong>Last used - </strong> {{ student.lastUsed }}
      </div>
      <div class="buttons-box">
        <div (click)="quickTopupClick(student)" class="portal-button">
          Quick topup
        </div>
      </div>
      <div class="buttons-box">
        <div class="portal-button">
          <div class="icon-button">
            <mat-icon
              class="icon button-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >settings</mat-icon
            >
            <div class="button-icon-text" (click)="selectStudentClick(student)">
              Edit details
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="add-new-student-button">
    <mat-icon
      class="add-student-icon"
      aria-hidden="false"
      aria-label="Example home icon"
      >add_circle</mat-icon
    >
    <div class="add-student-icon-text" (click)="addNewStudent()">
      Add new student
    </div>
  </div>
</div>
