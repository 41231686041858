<div class="portal-container" [style.minHeight.px]="screenHeight - 220">
  <div class="portal-heading">{{ categoryName }}</div>
  <div class="portal-subheading">
    Select from the items below
    <br />
  </div>
  <div class="header-icons-box">
    <div>
      <mat-icon
        class="category-icon"
        aria-hidden="false"
        aria-label="Example home icon"
        >fastfood</mat-icon
      >
    </div>
    <div class="checkout-box">
      <div>
      <div class="checkout-button" (click)="cartClicked()">
        <mat-icon
          class="checkout-button-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >shopping_cart</mat-icon
        >
        <div class="checkout-button-item-count-circle">
          <div class="checkout-button-item-count-text">
            {{ selectedItemCount }}
          </div>
        </div>
      </div>
      <div class="portal-subheading">View cart</div>        
      </div>
      <div class="student-picture-box">
        <img
          class="student-small-picture"
          [src]="selectedStudent.picture"
          width="60"
        />
      </div>
    </div>
  </div>
  <div class="items-box">
    <div class="item-row-box" *ngFor="let item of categoryItems">
      <div class="item-box-text">
        {{ item.description }} | R{{ item.price }}
      </div>
      <div class="item-box-buttons">
        <div
          *ngIf="item.qty > 0"
          class="item-box-remove-button"
          (click)="removeItem(item)"
        >
          <mat-icon
            class="item-box-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >remove</mat-icon
          >
        </div>
        <div class="item-box-add-button" (click)="addItem(item)">
          <mat-icon
            class="item-box-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >add</mat-icon
          >
        </div>
        <div *ngIf="item.qty > 0" class="item-box-item-count">
          {{ item.qty }}
        </div>
      </div>
    </div>
  </div>
  <div class="action-buttons-box">
    <div class="portal-alternate-button" (click)="backClicked()">
      Back
    </div>
  </div>
</div>
