<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 150"
>
  <div class="portal-heading">Add new Student</div>
  <div class="portal-subheading">
    Fill in the below details to add a new student<br />
  </div>
  <div class="students-container">
    <div>
      <div class="student-picture-parent" *ngIf="!showDoneAnimation">
        <img src="../../../../assets/profile.png" class="student-picture" />
      </div>
      <div class="student-picture-parent" *ngIf="showDoneAnimation">
        <lottie-player
          slot="end"
          autoplay
          src="https://assets5.lottiefiles.com/datafiles/kbuj8xpg2U73q9B/data.json"
        ></lottie-player>
      </div>
      <div class="buttons-box" [formGroup]="addStudent">
        <div class="small-text-field-box">
          <input
            class="med-text-field"
            formControlName="keyCard"
            placeholder="Key Card Number"
            type="text"
          />
        </div>
        <div class="small-text-field-box">
          <input
            class="med-text-field"
            formControlName="pin"
            placeholder="Pin Code"
            type="text"
          />
        </div>
        <div (click)="saveAddStudent()" class="portal-alternate-button">
          <div class="button-loader" *ngIf="isButtonLoading"></div>
          <div *ngIf="!isButtonLoading">Save</div>
        </div>
        <div class="portal-button" (click)="cancelAddStudent()">Cancel</div>
      </div>
    </div>
  </div>
</div>
