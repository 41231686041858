<div
  class="content"
  [style.height.px]="isMobile ? 'unset' : screenHeight * screenHeightPercent"
>
  <div class="register-box-heading">Registration</div>
  <div class="sub-text">
    Please fill the form out below. <br /><br />You need to enter your child’s
    card number and PIN code from the letter you received. <br />
    *NOTE you cannot register without the card number and pin code.
  </div>

  <div class="register-box" [formGroup]="registrationForm">
    <div class="register-box-heading-student">Your details</div>
    <div class="text-field-box">
      <input
        class="text-field"
        type="text"
        autocomplete="off"
        formControlName="name"
        placeholder="First Name"
      />
    </div>
    <div
      *ngIf="registrationForm.get('name').hasError('required') && isSubmitted"
    >
      <span class="hint-required">(*) Please enter in a name</span>
    </div>

    <div class="text-field-box">
      <input
        class="text-field"
        type="text"
        autocomplete="off"
        formControlName="surname"
        placeholder="Last Name"
      />
    </div>

    <div
      *ngIf="
        registrationForm.get('surname').hasError('required') && isSubmitted
      "
    >
      <span class="hint-required">(*) Please enter in a surname</span>
    </div>

    <div class="text-field-box">
      <input
        class="text-field"
        type="email"
        autocomplete="off"
        formControlName="email"
        placeholder="Email"
      />
    </div>

    <div
      *ngIf="registrationForm.get('email').hasError('required') && isSubmitted"
    >
      <span class="hint-required">(*) Please enter in an email</span>
    </div>

    <div class="text-field-box">
      <input
        class="text-field"
        type="password"
        autocomplete="off"
        formControlName="createPassword"
        minlength="8"
        placeholder="Create Password"
      />
    </div>
    <div
      *ngIf="
        registrationForm.get('createPassword').hasError('required') &&
        isSubmitted
      "
    >
      <span class="hint-required">(*) Required</span>
    </div>
    <div *ngIf="registrationForm.get('createPassword').hasError('minlength')">
      <span class="hint-required"
        >Password must be at least 8 characters in length</span
      >
    </div>

    <div class="text-field-box">
      <input
        class="text-field"
        type="password"
        autocomplete="off"
        formControlName="repeatPassword"
        placeholder="Repeat Password"
      />
    </div>
    <div
      *ngIf="
        registrationForm.get('repeatPassword').hasError('required') &&
        isSubmitted
      "
    >
      <span class="hint-required">(*) Required</span>
    </div>
    <div
      *ngIf="
        registrationForm.get('repeatPassword').value !==
        registrationForm.get('createPassword').value
      "
    >
      <span class="hint-required">Passwords dont match <br /></span>
    </div>

    <div class="register-box-heading-student">Your student's details</div>

    <div class="text-field-box">
      <input
        class="text-field"
        type="text"
        autocomplete="off"
        formControlName="cardNumber"
        placeholder="Card Number"
      />
    </div>
    <div
      *ngIf="
        registrationForm.get('cardNumber').hasError('required') && isSubmitted
      "
    >
      <span class="hint-required">(*) Required</span>
    </div>

    <div class="text-field-box">
      <input
        class="text-field"
        type="password"
        autocomplete="off"
        formControlName="pinNumber"
        placeholder="PIN Number"
      />
    </div>
    <div
      *ngIf="
        registrationForm.get('pinNumber').hasError('required') && isSubmitted
      "
    >
      <span class="hint-required">(*) Required</span>
    </div>
    <div class="terms-box">
      <mat-checkbox
        color="primary"
        formControlName="terms"
        (change)="toggelAccept()"
      >
        I agree to the
        <a href="/terms-and-conditions" target="_blank" class="grey-link-text"
          >terms and contitions</a
        >
      </mat-checkbox>
    </div>
    <div
      *ngIf="registrationForm.get('terms').hasError('required') && isSubmitted"
    >
      <span class="hint-required"
        >You need to agree to the terms and conditions</span
      >
    </div>
    <div class="buttons-box">
      <div class="registration-button" (click)="submitRegistration()">
        Register
      </div>
    </div>
    <div *ngIf="isError">
      <span class="hint-required">{{ error }}</span>
    </div>
  </div>
</div>
<app-footer [screenHeight]="screenHeight"></app-footer>
