import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TopupDetails } from '../Models/TopupDetails';
import { GeneralService } from './general.service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiServicesService {
  BaseApiURL = 'https://appapi.lunchcard.co.za';
  schoolDetails;

  public errorMessage: any;
  handleError(error: HttpErrorResponse) {
    this.errorMessage = error;
    return throwError(error);
  }

  constructor(
    private http: HttpClient,
    private generalService: GeneralService,
    public datepipe: DatePipe
  ) {}

  generateRequestOptions(): {} {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('lunchcardToken'),
    });

    return headers;
  }

  async login(emailAddress, passWord) {
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/user/login?emailAddress=' +
              emailAddress +
              '&password=' +
              passWord +
              '&notificationToken=null'
          )
        )
        .pipe(
          catchError((err) => {
            this.errorMessage = err.error;
            return err.error;
          })
        )
        .toPromise();
      if (result.token) {
        localStorage.setItem('lunchcardToken', result.token);
        return true;
      } else if (Array.isArray(result)) {
        return result;
      } else {
        return this.errorMessage;
      }
    } catch {
      return false;
    }
  }

  async loginById(schoolId, emailAddress, passWord) {
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/user/loginbyid?schoolId=' +
              schoolId +
              '&emailAddress=' +
              emailAddress +
              '&password=' +
              passWord +
              '&notificationToken=null'
          )
        )
        .pipe(
          catchError((err) => {
            this.errorMessage = err.error;
            return err.error;
          })
        )
        .toPromise();
      if (result.token) {
        localStorage.setItem('lunchcardToken', result.token);
        return true;
      } else {
        return this.errorMessage;
      }
    } catch {
      return false;
    }
  }

  async registration(payload) {
    try {
      const result = await this.http
        .post<any>(encodeURI(this.BaseApiURL + '/user/register'), payload, {
          headers: this.generateRequestOptions(),
        })
        .pipe(
          catchError((err) => {
            this.errorMessage = err.error;
            return err.error;
          })
        )
        .toPromise();
      if (result.token) {
        localStorage.setItem('lunchcardToken', result.token);
        return true;
      } else {
        return this.errorMessage;
      }
    } catch {
      return false;
    }
  }

  async forgotPassword(email) {
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL + '/user/requestpasswordreset?emailAddress=' + email
          )
        )
        .toPromise();
      if (result) {
        return result.result;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  async checkResetKey(resetKey) {
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL + '/user/checkresetkey?resetKey=' + resetKey
          ),
          {
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      return result;
    } catch {
      return false;
    }
  }

  async resetPassword(payload) {
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/user/savepasswordreset'),
          payload,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      return true;
    } catch {
      return false;
    }
  }

  async getStudents() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(encodeURI(this.BaseApiURL + '/students/getstudents'), {
          headers: this.generateRequestOptions(),
        })
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getSchoolDetails() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(encodeURI(this.BaseApiURL + '/general/getschooldetails'), {
          headers: this.generateRequestOptions(),
        })
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getParentDetails() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(encodeURI(this.BaseApiURL + '/general/getparentdetails'), {
          headers: this.generateRequestOptions(),
        })
        .toPromise();
      this.generalService.parentDetails = result;
      this.generalService.isLoading = false;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getAllParentsDetails() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(encodeURI(this.BaseApiURL + '/general/getparentdetails'), {
          headers: this.generateRequestOptions(),
        })
        .toPromise();
      this.generalService.isLoading = false;
      this.generalService.allParentsDetails = [result];
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async performTopup(topupDetails: TopupDetails) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/students/topupstudent'),
          topupDetails,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async initiatePayment(amount, autoTopup, paymentType) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(
            this.BaseApiURL +
              '/payment/initiatepayment?amount=' +
              amount +
              '&autoTopup=' +
              autoTopup +
              '&paymentType=' +
              paymentType
          ),
          null,
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getPaymentResult(paymentReference) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/payment/getpaymentresult?paymentReference=' +
              paymentReference
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getOrderDays(monthDate, studentId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/mealplanning/getorderdays?monthDate=' +
              monthDate +
              '&studentId=' +
              studentId
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getCategories() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(encodeURI(this.BaseApiURL + '/mealplanning/getcategories'), {
          headers: this.generateRequestOptions(),
        })
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getAllCategories() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(this.BaseApiURL + '/mealplanning/getallcategories'),
          {
            headers: this.generateRequestOptions(),
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getRestrictedCategories(studentId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/mealplanning/getrestrictedcategories?studentId=' +
              studentId
          ),
          {
            headers: this.generateRequestOptions(),
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getCategoryItems(categoryId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/mealplanning/getcategoryitems?categoryId=' +
              categoryId
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getRecentMealPlans() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(this.BaseApiURL + '/mealplanning/getrecentmealplans'),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getMealPlanningTimeSlots() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(encodeURI(this.BaseApiURL + '/mealplanning/gettimeslots'), {
          headers: this.generateRequestOptions(),
        })
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getMealPlanItemsForDate(studentId, selectedDate) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/mealplanning/getmealplanitemsfordate?studentId=' +
              studentId +
              '&selectedDate=' +
              selectedDate
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getMealPlanItems(mealPlanId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/mealplanning/getmealplanitems?mealPlanId=' +
              mealPlanId
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async CreateMealPlan(mealPlanDto) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/mealplanning/createmealplan'),
          mealPlanDto,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch (ex) {
      this.generalService.isLoading = false;

      return ex;
    }
  }

  async DeleteMealPlan(mealPlanId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(
            this.BaseApiURL +
              '/mealplanning/deletemealplan?mealPlanId=' +
              mealPlanId
          ),
          null,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async getReportDays(monthDate) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL + '/general/getreportdays?monthDate=' + monthDate
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getStudentMonthPurchases(studentId, monthDate) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/students/getstudentmonthpurchases?studentId=' +
              studentId +
              '&reportMonth=' +
              monthDate
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getDailySpentReport(studentId, monthDate) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/students/getdailyspendreport?studentId=' +
              studentId +
              '&reportMonth=' +
              monthDate
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getCategoryReport(studentId, monthDate) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/students/getcategoryreport?studentId=' +
              studentId +
              '&reportMonth=' +
              monthDate
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getStudentDetails(studentId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/students/getstudentdetails?studentId=' +
              studentId
          ),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async suspendStudentAccount(studentId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(
            this.BaseApiURL + '/students/suspendaccount?studentId=' + studentId
          ),
          null,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async activateStudentAccount(studentId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(
            this.BaseApiURL + '/students/activateaccount?studentId=' + studentId
          ),
          null,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async initiateReplacementCardPayment(studentId) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(
            this.BaseApiURL +
              '/payment/initiatereplacementpayment?studentId=' +
              studentId +
              '&paymentType=rc'
          ),
          null,
          {
            headers: this.generateRequestOptions(),
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async sendEmail(recpient, subject, htmlBody, textBody, name, email) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(
            this.BaseApiURL +
              '/publicApi/sendemail?recpient=' +
              recpient +
              '&subject=' +
              subject +
              '&htmlBody=' +
              htmlBody +
              '&textBody=' +
              textBody +
              '&name=' +
              name +
              '&email=' +
              email
          ),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async updateStudent(student) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/students/editstudentdetails'),
          student,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async updateParent(parent) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/general/editparentdetails'),
          parent,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async addParent(parent) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/students/addparent'), // TODO add API
          parent,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async updateRestrictions(restrictedCategories) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(this.BaseApiURL + '/students/setrestrictedcategories'), // TODO add API
          restrictedCategories,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async addStudent(keyCard, pin) {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .post<any>(
          encodeURI(
            this.BaseApiURL +
              '/students/addStudent?keyCard=' +
              keyCard +
              '&pin=' +
              pin
          ),
          null,
          {
            headers: this.generateRequestOptions(),
            responseType: 'text' as 'json',
          }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return true;
    } catch (ex) {
      this.generalService.isLoading = false;
      return ex;
    }
  }

  async getSchoolBankingDetails() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(this.BaseApiURL + '/payment/getschoolbankingdetails'),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }

  async getSchoolPermissions() {
    this.generalService.isLoading = true;
    try {
      const result = await this.http
        .get<any>(
          encodeURI(this.BaseApiURL + '/students/getschoolpermissions'),
          { headers: this.generateRequestOptions() }
        )
        .toPromise();
      this.generalService.isLoading = false;
      return result;
    } catch {
      this.generalService.isLoading = false;
      return null;
    }
  }
}
