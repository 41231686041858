import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
})
export class WebsiteComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  isMobile: boolean = false;
  public showMenu = false;
  public scrollTo: string;

  public location: string = "";
  public currentSection: string = "";

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 700) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  constructor(private router: Router) {
    this.getScreenSize();
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.location = this.router.url;
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => { };

  openMenu() {
    this.showMenu = true;
  }

  handleRouteClicked(route: string) {
    this.router.navigate(['/', route]);
  }

  emitNavClick(scrollToEvent) {
    this.scrollTo = scrollToEvent;
  }

}
