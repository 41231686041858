import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ApiServicesService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-validate-payment',
  templateUrl: './validate-payment.component.html',
  styleUrls: ['./validate-payment.component.scss'],
})
export class ValidatePaymentComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private apiServices: ApiServicesService,
    public generalService: GeneralService,
    private router: Router
  ) {
    this.getScreenSize();
  }
  @Input() isMobile: number;
  buttonText: string;
  paymentType: string;
  screenHeight: number;
  screenWidth: number;
  isLoading: boolean = true;
  headingText: string = 'Validating payment...';
  validatedText: string = '';
  autoTopupText: string = '';
  paymentValidated: boolean = false;
  paymentNotValidated: boolean = false;
  paymentResult: boolean;
  public isCurrentRequest = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.validatePayment();
  }

  async validatePayment() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    await this.apiServices.getParentDetails();
    this.paymentType = this.route.snapshot.queryParams['paymentType'];
    const paymentResult = await this.apiServices.getPaymentResult(
      this.route.snapshot.queryParams['paymentReference']
    );
    this.isLoading = false;
    this.generalService.parentDetails.balance = paymentResult.newBalance;
    this.paymentResult = paymentResult.result ? true : false;
    if (paymentResult.result == 1) {
      this.buttonText = 'Done';
      this.paymentValidated = true;
      this.headingText = 'Payment successful';
      if (this.route.snapshot.queryParams['paymentType'] == 'ip') {
        if (paymentResult.autoTopup) {
          this.autoTopupText = 'You selected auto topup';
          this.validatedText =
            'Your payment of R' +
            paymentResult.amount +
            " has been split between your students' accounts";
        } else {
          this.autoTopupText = "You didn't select auto topup";
          this.validatedText =
            'Your payment of R' +
            paymentResult.amount +
            ' has been successfully allocated to your parent account';
        }
      } else {
        this.router.navigate(['/', 'completemealplan']);
      }
    } else {
      this.buttonText = 'Back';
      this.headingText = 'Payment unsuccessful';
      this.validatedText = 'The payment could not be processed';
      this.paymentNotValidated = true;
    }
    this.isCurrentRequest = false;
  }

  doneClicked() {
    if (this.route.snapshot.queryParams['paymentType'] == 'ip') {
      if (this.paymentResult) {
        this.generalService.currentScreen = 'Students';
      } else {
        this.generalService.currentScreen = 'Deposit funds';
        this.generalService.currentChildScreen = 'Select option';
      }
    }
    this.router.navigate(['/', 'portal']);
  }
}
