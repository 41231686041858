<div class="heading-main contact-top-container">Get in touch</div>
<div
  *ngIf="!isMobile"
  class="content-box"
  [style.height.px]="screenHeight - 60"
>
  <div class="map-container">
    <div>
      <div class="mapouter">
        <div class="gmap_canvas">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7172.798998713761!2d28.066925!3d-25.9877276!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9571a45370926b%3A0x9a28afe8ceba5fda!2s9%20Monza%20Cl%2C%20Khayalami%20Park%2C%20Kyalami%2C%201684%2C%20South%20Africa!5e0!3m2!1sen!2sin!4v1730437975305!5m2!1sen!2sin"
          width="500" height="500" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-container">
    <div class="icon-detail-main-container">
      <div class="icon-detail-container">
        <div class="icon-box">
          <mat-icon class="icon" aria-hidden="false" aria-label="Location icon"
            >place</mat-icon
          >
        </div>
        <div class="icon-detail-container-text">
          <div>
            9 Monza Close, Kyalami Business Park,<br />
            Kyalami, Midrand <br />
            South Africa <br />
            1684<br />
          </div>
        </div>
      </div>

      <div class="icon-detail-container">
        <div class="icon-box">
          <mat-icon class="icon" aria-hidden="false" aria-label="Contact icon"
            >phone</mat-icon
          >
        </div>
        <div class="icon-detail-container-text">
          <div>
            Tel: +27 11 234 4447 <br />
            Backup line: +27 72 824 1046 <br />
            WhatsApp Only Line: <a style="color: black;" href="https://api.whatsapp.com/send?phone=+27820763429">+27 82 076 3429</a>
            </div>
        </div>
      </div>

      <div class="icon-detail-container">
        <div class="icon-box">
          <mat-icon class="icon" aria-hidden="false" aria-label="Location icon"
            >schedule</mat-icon
          >
        </div>
        <div class="icon-detail-container-text">
          <div>
            Monday - Friday <br />
            Hours (7:00 AM - 4:00 PM) <br />
          </div>
        </div>
      </div>
    </div>

    <div class="form-container">
      <div [formGroup]="contactForm" *ngIf="!isSubmittedComplete">
        <div class="text-field-box">
          <input
            class="text-field"
            type="text"
            autocomplete="off"
            formControlName="name"
            placeholder="Name*"
          />
        </div>
        <div
          *ngIf="contactForm.get('name').hasError('required') && isSubmitted"
        >
          <span class="hint-required">(*) Required</span>
        </div>
        <div class="text-field-box">
          <input
            class="text-field"
            type="text"
            autocomplete="off"
            formControlName="school"
            placeholder="School*"
          />
        </div>
        <div
          *ngIf="contactForm.get('school').hasError('required') && isSubmitted"
        >
          <span class="hint-required">(*) Required</span>
        </div>
        <div class="text-field-box">
          <input
            class="text-field"
            type="text"
            autocomplete="off"
            formControlName="email"
            placeholder="Email*"
          />
        </div>
        <div
          *ngIf="contactForm.get('email').hasError('required') && isSubmitted"
        >
          <span class="hint-required">(*) Required</span>
        </div>
        <div class="text-field-box">
          <input
            class="text-field"
            type="text"
            autocomplete="off"
            formControlName="phone"
            placeholder="Phone Number*"
          />
        </div>
        <div
          *ngIf="contactForm.get('phone').hasError('required') && isSubmitted"
        >
          <span class="hint-required">(*) Required</span>
        </div>
        <div class="textarea-field-box">
          <textarea
            class="text-field"
            rows="11"
            type="text"
            autocomplete="off"
            formControlName="message"
            placeholder="Your message..."
          ></textarea>
        </div>
        <div
          *ngIf="contactForm.get('message').hasError('required') && isSubmitted"
        >
          <span class="hint-required">(*) Required</span>
        </div>
        <div class="buttons-box">
          <div class="submit-button" (click)="submitQuery()">
            <div class="button-loader" *ngIf="isButtonLoading"></div>
            <div *ngIf="!isButtonLoading">Submit</div>
          </div>
        </div>
      </div>

      <div *ngIf="isSubmittedComplete">
        <div class="thank-you-text">
          Thank you for reaching out, <br />
          your message has been send to the team
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isMobile" class="content-box">
  <div class="form-container">
    <div [formGroup]="contactForm" *ngIf="!isSubmittedComplete">
      <div class="text-field-box">
        <input
          class="text-field"
          type="text"
          autocomplete="off"
          formControlName="name"
          placeholder="Name*"
        />
      </div>
      <div *ngIf="contactForm.get('name').hasError('required') && isSubmitted">
        <span class="hint-required">(*) Required</span>
      </div>
      <div class="text-field-box">
        <input
          class="text-field"
          type="text"
          autocomplete="off"
          formControlName="school"
          placeholder="School*"
        />
      </div>
      <div
        *ngIf="contactForm.get('school').hasError('required') && isSubmitted"
      >
        <span class="hint-required">(*) Required</span>
      </div>
      <div class="text-field-box">
        <input
          class="text-field"
          type="text"
          autocomplete="off"
          formControlName="email"
          placeholder="Email*"
        />
      </div>
      <div *ngIf="contactForm.get('email').hasError('required') && isSubmitted">
        <span class="hint-required">(*) Required</span>
      </div>
      <div class="text-field-box">
        <input
          class="text-field"
          type="text"
          autocomplete="off"
          formControlName="phone"
          placeholder="Phone Number*"
        />
      </div>
      <div *ngIf="contactForm.get('phone').hasError('required') && isSubmitted">
        <span class="hint-required">(*) Required</span>
      </div>
      <div class="textarea-field-box">
        <textarea
          class="text-field"
          rows="11"
          type="text"
          autocomplete="off"
          formControlName="message"
          placeholder="Your message..."
        ></textarea>
      </div>
      <div
        *ngIf="contactForm.get('message').hasError('required') && isSubmitted"
      >
        <span class="hint-required">(*) Required</span>
      </div>
      <div class="buttons-box">
        <div class="submit-button" (click)="submitQuery()">Submit</div>
      </div>
    </div>

    <div *ngIf="isSubmittedComplete">
      <div class="thank-you-text">
        Thank you for reaching out, <br />
        your message has been sent to the team
      </div>
    </div>
  </div>

  <div class="icon-detail-main-container">
    <div class="icon-detail-container">
      <div class="icon-box">
        <mat-icon class="icon" aria-hidden="false" aria-label="Location icon"
          >place</mat-icon
        >
      </div>
      <div class="icon-detail-container-text">
        <div>
          Studio Park Offices<br />
          Lonehill, Johannesburg <br />
          South Africa <br />
          2191<br />
        </div>
      </div>
    </div>

    <div class="icon-detail-container">
      <div class="icon-box">
        <mat-icon class="icon" aria-hidden="false" aria-label="Contact icon"
          >phone</mat-icon
        >
      </div>
      <div class="icon-detail-container-text">
        <div>
          Tel: 011 234 4447 <br />
          Backup line: 082 387 7133 <br />
        </div>
      </div>
    </div>

    <div class="icon-detail-container">
      <div class="icon-box">
        <mat-icon class="icon" aria-hidden="false" aria-label="Location icon"
          >schedule</mat-icon
        >
      </div>
      <div class="icon-detail-container-text">
        <div>
          Monday - Friday <br />
          Hours (7:00 AM - 4:00 PM) <br />
        </div>
      </div>
    </div>
  </div>

  <div class="map-container">
    <div>
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            width="500"
            height="500"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=lunch%20card%20johannesburg&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe
          ><a href=""></a>
        </div>
      </div>
    </div>
  </div>
</div>
