<app-portal-menu-bar [isMobile]="isMobile"></app-portal-menu-bar>
<div class="menu-download-link" *ngIf="menuUrl">
  <a class="menu-download-link" [href]="menuUrl" download target="_blank"
    >*Click to download the full menu</a
  >
</div>
<app-students
  *ngIf="generalService.currentScreen == 'Students'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-students>
<app-topup
  *ngIf="generalService.currentScreen == 'Topup'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-topup>
<app-quick-topup
  *ngIf="generalService.currentScreen == 'Quick topup'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-quick-topup>
<app-deposit-funds
  *ngIf="generalService.currentScreen == 'Deposit funds'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-deposit-funds>
<app-meal-planning
  *ngIf="generalService.currentScreen == 'Meal planning'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-meal-planning>
<app-reports
  *ngIf="generalService.currentScreen == 'Reports'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-reports>
<app-profile
  *ngIf="generalService.currentScreen == 'profile'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
></app-profile>
<app-footer [screenHeight]="screenHeight"></app-footer>
