import { Component, OnInit, Input, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;

  private _scrollTo: string;
    
  @Input() set scrollTo(value: string) {
  
     this._scrollTo = value;
     if (this._scrollTo) {
       this.scrollToPageElement(this._scrollTo);
     }    
  
  }
  
  get categoryId(): string {  
      return this._scrollTo;  
  }


  public smallScreen = false;
  public activeElement: HTMLElement;
  public location: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.screenSizeAdjustmentForBox();

    this.location = this.activatedRoute.snapshot.routeConfig.path;

    if(this.location && this.location != 'home'){
      this.activeElement = document.getElementById(this.location);
      this.scrollToElement();      
    }
    
  }

  screenSizeAdjustmentForBox() {
    if (this.screenWidth < 1240 && !this.isMobile) {
      this.smallScreen = true;
    }
  }

  moreInfo($element) {
    this.activeElement = $element;
    this.scrollToElement();
  }

  scrollToElement() {
     this.activeElement.scrollIntoView({ behavior: "smooth" });
  }
  
  scrollToPageElement(element) {
    this.activeElement = document.getElementById(element);
    this.activeElement.scrollIntoView({ behavior: "smooth" });
  }
}
