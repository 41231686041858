<div class="portal-container" [style.height.px]="screenHeight - 220">
  <div class="portal-heading">Select order date</div>
  <div class="portal-subheading">
    Select the date for
    {{ selectedStudent ? selectedStudent.name : "" }} order<br />
  </div>
  <div class="date-selection-box">
    <div class="header-bar">
      <div class="button-circle" (click)="setMonth(-1)">
        <mat-icon
          class="button-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >arrow_back</mat-icon
        >
      </div>
      <div class="header-bar-text">
        {{ currentMonthDate.format("MMMM yyyy") }}
      </div>
      <div class="button-circle" (click)="setMonth(1)">
        <mat-icon
          class="button-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >arrow_forward</mat-icon
        >
      </div>
    </div>
    <div class="days-box">
      <div class="day-names-row">
        <div class="day-name-text">Mon</div>
        <div class="day-name-text">Tue</div>
        <div class="day-name-text">Wed</div>
        <div class="day-name-text">Thur</div>
        <div class="day-name-text last-day-name-text">Fri</div>
      </div>
      <div class="day-names-row" *ngFor="let dayRow of dayRows">
        <div
          class="day-name-text day-text"
          [ngClass]="{ 'last-day-box': (i + 1) % 5 == 0 }"
          *ngFor="let day of dayRow; let i = index"
        >
          <div
            class="day-box"
            (click)="selectDay(day)"
            [style.backgroundColor]="
              day.greyedOut ? 'lightgrey' : day.orderExists ? 'lightblue' : ''
            "
          >
            {{ day.displayDay }}
          </div>
        </div>
      </div>
    </div>
    <div class="header-bar">
      <div class="key-boxes">
        <div class="past-orders-key-box">
          <div class="past-orders-key-circle"></div>
          <div class="past-orders-key-text">Past orders</div>
        </div>
        <div class="greyed-out-key-box">
          <div class="not-available-key-circle"></div>
          <div class="past-orders-key-text">Not available</div>
        </div>
      </div>
      <div class="student-circle">
        <img
          class="student-circle-picture"
          [src]="selectedStudent.picture"
          width="40"
        />
      </div>
    </div>
  </div>
</div>
