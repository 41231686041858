import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { controllers } from 'chart.js';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-student-selection',
  templateUrl: './student-selection.component.html',
  styleUrls: ['./student-selection.component.scss'],
})
export class StudentSelectionComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;
  @Input() students: BasicStudent[];
  @Output() quickTopup = new EventEmitter();
  @Output() selectStudent = new EventEmitter();
  public isStudentsInactive: boolean = false;

  constructor(public generalService: GeneralService) {}

  ngOnInit(): void {
    if (this.screenHeight < 750) {
      // Smaller Laptop Screens
      this.screenHeight = this.screenHeight * 1.3;
    }

    if (this.screenWidth < 700) {
      // Mobile
      this.screenHeight = this.screenHeight * 1.1;
    }
  }

  selectStudentClick(student) {
    this.selectStudent.emit(student);
  }

  quickTopupClick(student) {
    this.quickTopup.emit(student);
  }

  addNewStudent() {
    this.isStudentsInactive = false;
    this.students.forEach((student) => {
      if (student.status === 'Inactive') {
        this.isStudentsInactive = true;
      }
    });

    if (!this.isStudentsInactive) {
      this.generalService.currentChildScreen = 'Add Student';
      this.generalService.currentScreen = 'Students';
    } else {
      alert('Please activate current students before adding a new one');
    }
  }
}
