import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-reports-student-selection',
  templateUrl: './reports-student-selection.component.html',
  styleUrls: ['./reports-student-selection.component.scss'],
})
export class ReportsStudentSelectionComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() isMobile: number;
  @Input() students;
  @Output() handleStudentClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  selectStudent(student) {
    this.handleStudentClicked.emit(student);
  }
}
