import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-meal-planning-time-selection',
  templateUrl: './meal-planning-time-selection.component.html',
  styleUrls: ['./meal-planning-time-selection.component.scss'],
})
export class MealPlanningTimeSelectionComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() recentMealPlans;
  @Input() isMealPlanningAllowed;
  @Input() selectedStudent: BasicStudent;
  @Input() timeSlots;
  @Output() handleTimeSlotClicked = new EventEmitter();

  constructor(private generalService: GeneralService) {}

  ngOnInit(): void {}

  timeSlotClicked(timeSlot) {
    this.handleTimeSlotClicked.emit(timeSlot);
  }

  cancel() {
    this.generalService.currentScreen = 'Students';
  }
}
