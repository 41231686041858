import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  
  constructor() { }

  ngOnInit(): void {
  }

}
