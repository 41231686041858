<div class="top-container" [formGroup]="parentDetail">
  <div class="student-picture-container">
    <div class="student-picture-parent">
      <img src="../../../../assets/profile.png" class="student-picture" draggable="false" />
    </div>
    <div class="toggle-container">
      <mat-slide-toggle color="primary" formControlName="isLowBalanceNotifications" (change)="saveParent()">
        Low Balance Notifications
      </mat-slide-toggle>
      <mat-slide-toggle color="primary" formControlName="isAutoTopup" (change)="saveParent()">
        AutoTopup
      </mat-slide-toggle>
    </div>
  </div>
  <div class="details-container">
    <div class="details-heading-container">
      <div class="details-heading">
        {{ parent.name }} {{ parent.surname }}
      </div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text" *ngIf="!editName">
        Name: {{ parent.name }}
      </div>
      <div class="details-row-text" *ngIf="editName">
        <input class="text-field-details" type="text" autocomplete="off" formControlName="name"
          placeholder="Enter Name" />
      </div>
      <div class="details-row-edit-button" *ngIf="!editName" (click)="editName = !editName">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      </div>
      <div class="details-row-edit-button" *ngIf="editName" (click)="saveParent(); editName = !editName">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">save</mat-icon>
      </div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text" *ngIf="!editSurname">
        Surname: {{ parent.surname }}
      </div>
      <div class="details-row-text" *ngIf="editSurname">
        <input class="text-field-details" type="text" autocomplete="off" formControlName="surname"
          placeholder="Enter Surname" />
      </div>
      <div class="details-row-edit-button" *ngIf="!editSurname" (click)="editSurname = !editSurname">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      </div>
      <div class="details-row-edit-button" *ngIf="editSurname" (click)="saveParent(); editSurname = !editSurname">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">save</mat-icon>
      </div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text" *ngIf="!editEmail">
        Email: {{ parent.emailAddress }}
      </div>
      <div class="details-row-text" *ngIf="editEmail">
        <input class="text-field-details" type="text" autocomplete="off" formControlName="email"
          placeholder="Enter Email" />
      </div>
      <!-- <div class="details-row-edit-button" *ngIf="!editEmail" (click)="editEmail = !editEmail">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      </div>
      <div class="details-row-edit-button" *ngIf="editEmail" (click)="saveParent(); editEmail = !editEmail">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">save</mat-icon>
      </div> -->
    </div>
    <div class="details-row-container">
      <div class="details-row-text" *ngIf="!editPhoneNumber">
        Cellphone Number: {{ parent.cellphoneNumber }}
      </div>
      <div class="details-row-text" *ngIf="editPhoneNumber">
        <input class="text-field-details" type="text" autocomplete="off" formControlName="phoneNumber"
          placeholder="Enter Cellphone Number" />
      </div>
      <div class="details-row-edit-button" *ngIf="!editPhoneNumber" (click)="editPhoneNumber = !editPhoneNumber">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      </div>
      <div class="details-row-edit-button" *ngIf="editPhoneNumber"
        (click)="saveParent(); editPhoneNumber = !editPhoneNumber">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">save</mat-icon>
      </div>
    </div>

    <div class="details-row-container">
      <div class="details-row-text" *ngIf="!editBalanceNotificationAmount">
        Notification Amount: {{ parent.balanceNotificationAmount }}
      </div>
      <div class="details-row-text" *ngIf="editBalanceNotificationAmount">
        <input class="text-field-details" type="text" autocomplete="off" formControlName="balanceNotificationAmount"
          placeholder="Notification Amount" />
      </div>
      <div class="details-row-edit-button" *ngIf="!editBalanceNotificationAmount"
        (click)="editBalanceNotificationAmount = !editBalanceNotificationAmount">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      </div>
      <div class="details-row-edit-button" *ngIf="editBalanceNotificationAmount"
        (click)="saveParent(); editBalanceNotificationAmount = !editBalanceNotificationAmount">
        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Example home icon">save</mat-icon>
      </div>
    </div>
    <!-- <div class="details-row-container">
        <div class="details-row-text" *ngIf="!editPassword">
          Password: {{ parent.password }}
        </div>
        <div class="details-row-text" *ngIf="editPassword">
          <input class="text-field-details" type="text" autocomplete="off" formControlName="passowrd"
                 placeholder="Enter Password" />
        </div>
        <div class="details-row-edit-button" *ngIf="!editPassword" (click)="editPassword = !editPassword">
          <mat-icon class="edit-icon"
                    aria-hidden="false"
                    aria-label="Example home icon">edit</mat-icon>
        </div>
        <div class="details-row-edit-button" *ngIf="editPassword" (click)="saveParent(); editPassword = !editPassword">
          <mat-icon class="edit-icon"
                    aria-hidden="false"
                    aria-label="Example home icon">save</mat-icon>
        </div>
      </div> -->
  </div>
</div>