<app-menubar *ngIf="!isMobile"></app-menubar>
<div class="content" [style.height.px]="screenHeight * 0.7 - 15">
  <div
    class="login-box"
    [formGroup]="loginForm"
    (keydown)="enterSubmit($event)"
    *ngIf="!isForgotPassword"
  >
    <div class="login-box-heading">Login</div>
    <div class="text-field-box">
      <input
        class="text-field"
        placeholder="Email address"
        formControlName="emailAddress"
      />
    </div>
    <div class="text-field-box">
      <input
        class="text-field"
        type="password"
        placeholder="Password"
        formControlName="passWord"
      />
    </div>
    <div class="school-account-text" *ngIf="showSchoolSelector">
      Select the school account to use
    </div>
    <div class="text-field-box" *ngIf="showSchoolSelector">
      <select
        class="text-field"
        (change)="setSelectedSchool($event.target.value)"
      >
        <option value="0">Select an option</option>
        <option
          *ngFor="let schoolOption of schoolOptions"
          [value]="schoolOption.schoolId"
        >
          {{ schoolOption.schoolName }}
        </option>
      </select>
    </div>
    <div class="buttons-box">
      <div class="login-button" (click)="attemptLogin()">
        <div class="button-loader" *ngIf="isButtonLoading"></div>
        <div *ngIf="!isButtonLoading">Login</div>
      </div>
      <div class="forgot-password-button" (click)="isForgotPassword = true">
        Forgot password
      </div>
    </div>
    <div *ngIf="isError">
      <span class="hint-required">{{ error }}</span>
    </div>
  </div>

  <div
    class="login-box"
    [formGroup]="forgotPasswordForm"
    (keydown)="enterSubmit($event)"
    *ngIf="isForgotPassword"
  >
    <div class="login-box-heading">Forgot Password</div>
    <div class="text-field-box" *ngIf="!isPasswordRequestComplete">
      <input
        class="text-field"
        placeholder="Email address"
        type="email"
        formControlName="emailAddress"
      />
    </div>

    <div
      *ngIf="
        forgotPasswordForm.get('emailAddress').invalid &&
        forgotPasswordForm.get('emailAddress').touched
      "
    >
      <span class="hint-required">Please enter in a valid email</span>
    </div>

    <div *ngIf="isPasswordRequestComplete">
      <div class="done-message">
        Done! <br />
        <br />
        If you have an account with lunchcard you will recieve an email with
        more details
      </div>
    </div>
    <div class="buttons-box">
      <div
        class="login-button"
        (click)="requestPassword()"
        *ngIf="!isPasswordRequestComplete"
      >
        <div class="button-loader" *ngIf="isButtonLoading"></div>
        <div *ngIf="!isButtonLoading">Request Password</div>
      </div>
      <div
        class="forgot-password-button"
        (click)="isForgotPassword = false; isPasswordRequestComplete = false"
      >
        Back
      </div>
    </div>
  </div>
</div>
<app-footer [screenHeight]="screenHeight"></app-footer>
