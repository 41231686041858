<div class="content" *ngIf="!isMobile">
  <div class="profile-box">
    <div class="profile-circle">
      {{ generalService.parentDetails?.name | titlecase | slice: 0:1 }}
      {{ generalService.parentDetails?.surname | titlecase | slice: 0:1 }}
    </div>
    <div class="profile-name">
      {{ generalService.parentDetails?.name }}
      {{ generalService.parentDetails?.surname }}
    </div>
  </div>
  <div class="buttons-box">
    <div
      class="button-box"
      (click)="changeScreen('Students', 'Student selection')"
    >
      <div class="icon-box">
        <mat-icon
          [ngClass]="
            generalService.currentScreen == 'Students'
              ? 'selected-icon'
              : 'icon'
          "
          aria-hidden="false"
          aria-label="Example home icon"
          >person</mat-icon
        >
      </div>
      <div
        [ngClass]="
          generalService.currentScreen == 'Students'
            ? 'selected-icon-text'
            : 'icon-text'
        "
      >
        Students
      </div>
    </div>
    <div class="button-box" (click)="changeScreen('Topup', 'Enter amount')">
      <div class="icon-box">
        <mat-icon
          [ngClass]="
            generalService.currentScreen == 'Topup' ? 'selected-icon' : 'icon'
          "
          aria-hidden="false"
          aria-label="Example home icon"
          >account_balance_wallet</mat-icon
        >
      </div>
      <div
        [ngClass]="
          generalService.currentScreen == 'Topup'
            ? 'selected-icon-text'
            : 'icon-text'
        "
      >
        Topup
      </div>
    </div>
    <div
      class="button-box"
      (click)="changeScreen('Deposit funds', 'Select option')"
    >
      <div class="icon-box">
        <mat-icon
          [ngClass]="
            generalService.currentScreen == 'Deposit funds'
              ? 'selected-icon'
              : 'icon'
          "
          aria-hidden="false"
          aria-label="Example home icon"
          >account_balance</mat-icon
        >
      </div>
      <div
        [ngClass]="
          generalService.currentScreen == 'Deposit funds'
            ? 'selected-icon-text'
            : 'icon-text'
        "
      >
        Deposit funds
      </div>
    </div>
    <div class="button-box" (click)="changeScreen('Meal planning', 'Index')">
      <div class="icon-box">
        <mat-icon
          [ngClass]="
            generalService.currentScreen == 'Meal planning'
              ? 'selected-icon'
              : 'icon'
          "
          aria-hidden="false"
          aria-label="Example home icon"
          >favorite</mat-icon
        >
      </div>
      <div
        [ngClass]="
          generalService.currentScreen == 'Meal planning'
            ? 'selected-icon-text'
            : 'icon-text'
        "
      >
        Meal planning
      </div>
    </div>
    <div
      class="button-box"
      (click)="changeScreen('Reports', 'Student selection')"
    >
      <div class="icon-box">
        <mat-icon
          [ngClass]="
            generalService.currentScreen == 'Reports' ? 'selected-icon' : 'icon'
          "
          aria-hidden="false"
          aria-label="Example home icon"
          >bar_chart</mat-icon
        >
      </div>
      <div
        [ngClass]="
          generalService.currentScreen == 'Reports'
            ? 'selected-icon-text'
            : 'icon-text'
        "
      >
        Reports
      </div>
    </div>
  </div>
  <div class="end-buttons-box">
    <div class="settings-button">
      <mat-icon
        class="icon settings-icon"
        aria-hidden="false"
        aria-label="Example home icon"
        (click)="changeScreen('profile', '')"
        >settings</mat-icon
      >
    </div>
    <div class="menu-balance-box">
      <div class="menu-balance-deposit">
        <mat-icon
          class="menu-balance-deposit-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          (click)="changeScreen('Deposit funds', 'Select option')"
          >add_circle</mat-icon
        >
      </div>
      <div class="menu-balance-text">
        R{{
          generalService.parentDetails
            ? generalService.parentDetails.balance
            : 0
        }}
      </div>
    </div>
    <div class="logout-button" (click)="logout()">Logout</div>
  </div>
</div>

<div class="content" *ngIf="isMobile">
  <div class="buttons-box">
    <div
      class="button-box"
      (click)="changeScreen('Students', 'Student selection')"
    >
      <div class="icon-box">
        <mat-icon
          class="icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >person</mat-icon
        >
      </div>
      <div class="icon-text">Students</div>
    </div>
    <div class="button-box" (click)="changeScreen('Topup', 'Enter amount')">
      <div class="icon-box">
        <mat-icon
          class="icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >account_balance_wallet</mat-icon
        >
      </div>
      <div class="icon-text">Topup</div>
    </div>
    <div
      class="button-box"
      (click)="changeScreen('Deposit funds', 'Select option')"
    >
      <div class="icon-box">
        <mat-icon
          class="icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >account_balance</mat-icon
        >
      </div>
      <div class="icon-text">Deposit funds</div>
    </div>
    <div class="button-box" (click)="changeScreen('Meal planning', 'Index')">
      <div class="icon-box">
        <mat-icon
          class="icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >favorite</mat-icon
        >
      </div>
      <div class="icon-text">Meal planning</div>
    </div>
    <div
      class="button-box"
      (click)="changeScreen('Reports', 'Student selection')"
    >
      <div class="icon-box">
        <mat-icon
          class="icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >bar_chart</mat-icon
        >
      </div>
      <div class="icon-text">Reports</div>
    </div>
  </div>
</div>
<div
  class="see-more"
  *ngIf="!isSeeMore && isMobile"
  (click)="isSeeMore = !isSeeMore"
>
  <div class="see-more-box">
    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon"
      >arrow_drop_down_circle</mat-icon
    >
    <div class="more-options-text">More options</div>
  </div>
</div>
<div
  class="see-more"
  *ngIf="isSeeMore && isMobile"
  (click)="isSeeMore = !isSeeMore"
>
  <span>See less</span>
</div>
<div class="end-buttons-box" *ngIf="isSeeMore && isMobile">
  <div class="menu-balance-box">
    <div class="menu-balance-deposit">
      <mat-icon
        class="menu-balance-deposit-icon"
        aria-hidden="false"
        aria-label="Example home icon"
        (click)="changeScreen('Deposit funds', 'Select option')"
        >add_circle</mat-icon
      >
    </div>
    <div class="menu-balance-text">
      R{{
        generalService.parentDetails ? generalService.parentDetails.balance : 0
      }}
    </div>
  </div>
  <div class="settings-button">
    <mat-icon
      class="icon settings-icon"
      aria-hidden="false"
      aria-label="Example home icon"
      (click)="changeScreen('profile', '')"
      >settings</mat-icon
    >
  </div>
</div>
<div class="loader-place-holder" *ngIf="!generalService.isLoading"></div>
<mat-progress-bar
  *ngIf="generalService.isLoading"
  mode="indeterminate"
></mat-progress-bar>
