import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParentDetails } from 'src/app/Models/ParentDetails';
import { StudentDetails } from 'src/app/Models/StudentDetails';
import { GeneralService } from 'src/app/services/general.service';
import { ApiServicesService } from '../../services/api-services.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() currentStudent: StudentDetails;
  @Input() isMobile: number;

  public editName = false;
  public editSurname = false;
  public editGrade = false;
  public editDailyLimit = false;
  public studentDetail: FormGroup;
  public parent: ParentDetails;
  public allParentsDetails: ParentDetails[];
  public isAddNew = false;

  constructor(private fb: FormBuilder, public generalService: GeneralService) {}

  ngOnInit(): void {
    if (this.screenHeight < 750 || this.screenWidth < 700) {
      this.screenHeight = this.screenHeight * 1.3;
    }

    this.parent = this.generalService.parentDetails;
    this.allParentsDetails = this.generalService.allParentsDetails;
  }

  addNewParent() {}
}
