<div class="menu-bar">
  <div class="menu-button" (click)="handleOpenMenuClicked()">
    <mat-icon class="menu-button-icon">menu</mat-icon>
  </div>
  <div class="logo-box">
    <img src="../../assets/logo.png" width="150" />
  </div>
  <div class="link-button link-primary-button" (click)="handleLoginClicked()">
    Login
  </div>
</div>
