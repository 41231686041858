<app-menubar *ngIf="!isMobile"></app-menubar>
<div class="content" [style.height.px]="screenHeight * 0.7 - 15">
    
    <div class="login-box" [formGroup]="forgotPasswordForm" (keydown)="enterSubmit($event)" *ngIf="!isResetComplete && !error"> 
        <div class="login-box-heading">Reset Password</div>
        <div class="text-field-box">
            <input class="text-field" placeholder="Password" type="password" formControlName="passWord" />
        </div>
        <div class="text-field-box">
            <input class="text-field" type="password" placeholder="Repeat password" formControlName="passWordRepeat" />
        </div>
        <div class="buttons-box">
            <div class="login-button" (click)="reset()">
                <div class="button-loader" *ngIf="isButtonLoading"></div>
                <div *ngIf="!isButtonLoading">Reset</div>
            </div>
        </div>
        <div *ngIf="!isPasswordMatching">
            <span class="hint-required">Passwords dont match</span>
        </div>
        <div *ngIf="forgotPasswordForm.get('passWord').hasError('minlength')">
            <span class="hint-required"
              >Password must be at least 8 characters in length</span
            >
          </div>
    </div>

    <div class="login-box" *ngIf="error"> 
        <div class="login-box-heading">Error</div>        
        <div>
            <span class="hint-required">{{ error }}</span>
        </div>
    </div>

    <div class="login-box" *ngIf="isResetComplete && !error">
        <div class="login-box-heading">Password Reset Complete</div>

        <div>
            <div class="done-message">
                <span> Done! <br /> <br />Continue to login and use your new password to login</span>
            </div>
        </div>

        <div class="buttons-box">
            <div class="login-button" (click)="loginNavigate()">
                <div>Go to Login</div>
            </div>
        </div>
    </div>
</div>
<app-footer [screenHeight]="screenHeight"></app-footer>