import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { BasicStudent } from 'src/app/Models/BasicStudent';

@Component({
  selector: 'app-meal-planning-categories',
  templateUrl: './meal-planning-categories.component.html',
  styleUrls: ['./meal-planning-categories.component.scss'],
})
export class MealPlanningCategoriesComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() selectedStudent: BasicStudent;
  @Input() categories;
  @Input() selectedItemCount;
  @Output() categorySelected = new EventEmitter();
  @Output() handleCartClicked = new EventEmitter();

  monthDays = [];

  constructor(
    private apiServices: ApiServicesService,
    private generalService: GeneralService
  ) {}

  cartClicked() {
    this.handleCartClicked.emit();
  }

  selectCategory(category) {
    this.categorySelected.emit(category);
  }

  ngOnInit(): void {}
}
