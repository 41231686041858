<div
  class="portal-container"
  [style.minHeight.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading">Restrict categories</div>
  <div class="portal-subheading">
    Select from the categories below that should be restricted from purchasing
    <br />
  </div>
  <div class="header-box">
    <div class="student-picture-container">
      <div class="student-picture-parent">
        <img
          src="{{ currentStudent.basicDetails.picture }}"
          class="student-picture"
          draggable="false"
        />
        <div class="balance-box">
          <div class="balance-box-child">
            Balance: R{{ currentStudent.basicDetails.balance }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="categories-box">
    <div *ngFor="let category of categories" class="category-box">
      <div (click)="selectCategory(category)">
        <mat-icon
          class="category-icon"
          svgIcon="{{ category.iconName }}"
        ></mat-icon>
        <mat-icon *ngIf="!category?.restricted" class="allow-icon"
          >check_circle_outline</mat-icon
        >
        <mat-icon *ngIf="category?.restricted" class="restrict-icon"
          >highlight_off</mat-icon
        >
      </div>
      <div class="category-text">{{ category.description }}</div>
    </div>
  </div>
  <div class="portal-button-cancel" (click)="cancel()">Back</div>
</div>
