import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';
import { InvokeFunctionExpr } from '@angular/compiler';
import { BasicStudent } from 'src/app/Models/BasicStudent';

@Component({
  selector: 'app-meal-planning-item-selection',
  templateUrl: './meal-planning-item-selection.component.html',
  styleUrls: ['./meal-planning-item-selection.component.scss'],
})
export class MealPlanningItemSelectionComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() categoryItems;
  @Input() categoryName: string = 'Item Selection';
  @Input() selectedItemCount;
  @Input() selectedStudent: BasicStudent;
  @Output() itemAdded = new EventEmitter();
  @Output() itemRemoved = new EventEmitter();
  @Output() handleBackClicked = new EventEmitter();
  @Output() handleCartClicked = new EventEmitter();

  monthDays = [];

  constructor(
    private apiServices: ApiServicesService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {}

  cartClicked() {
    this.handleCartClicked.emit();
  }

  addItem(item) {
    this.itemAdded.emit(item);
  }

  removeItem(item) {
    this.itemRemoved.emit(item);
  }

  backClicked() {
    this.handleBackClicked.emit('Category selection');
  }
}
