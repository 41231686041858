<app-portal-menu-bar></app-portal-menu-bar>
<div class="portal-container" [style.height.px]="screenHeight - 230">
  <div class="portal-heading">Order complete</div>
  <div class="auto-topup-text">
    Your order for a replacement card has been completed
  </div>
  <div class="validated-text">
    The card will be delivered to the school in the next 3-5 working days
  </div>
  <div class="button-container">
    <div class="portal-button" (click)="doneClicked()">Done</div>
  </div>
</div>
