import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ApiServicesService } from 'src/app/services/api-services.service';
import * as moment from 'moment';
import { ParentDetails } from 'src/app/Models/ParentDetails';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-meal-planning',
  templateUrl: './meal-planning.component.html',
  styleUrls: ['./meal-planning.component.scss'],
})
export class MealPlanningComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;

  orderDays = [];
  timeSlots = [];
  categories = [];
  students = [];
  orderNotes = '';
  selectedStudent: BasicStudent;
  selectedOrderDate;
  selectedTimeSlot;
  selectedCategory;
  selectedMealPlanId = 0;
  categoryName;
  categoryItems = [];
  selectedItems = [];
  selectedItemCount = 0;
  parentDetails: ParentDetails;
  mealPlanDetails;
  recentMealPlans = [];
  orderTotal = 0;
  isMealPlanningAllowed = false;
  schoolDetails;
  public isCurrentRequest = false;
  @ViewChild('paymentForm') paymentForm: ElementRef;
  webRequestForm: FormGroup;
  errorText: string = '';
  cardRequestForm: FormGroup;
  webpaymentData: any;
  webData = [];
  PAY_REQUEST_ID = '';
  CHECKSUM = '';
  autoTopup: Boolean = false;
  isExistingMealPlan: boolean;

  public isButtonLoading: boolean = false;

  constructor(
    public generalService: GeneralService,
    private apiServices: ApiServicesService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getRecentMealPlans();
    this.getStudents();
    this.getCategories();
    this.getOrderDays();
    this.getTimeSlots();
    this.getParentDetails();
    this.getSchoolPermisions();
    this.cardRequestForm = this.fb.group({
      PAY_REQUEST_ID: ['', Validators.required],
      CHECKSUM: ['', Validators.required],
    });
  }

  getSchoolPermisions() {
    // TODO
    this.isMealPlanningAllowed = true;
  }

  async studentSelected(student) {
    this.schoolDetails = await this.apiServices.getSchoolDetails();
    if (this.schoolDetails.mealPlanningAllowed) {
      this.selectedStudent = student;
      this.generalService.currentChildScreen = 'Date selection';
    } else {
      this.generalService.showNotification(
        'Unavailable',
        'Meal planning in currently not active for this school'
      );
    }
  }

  async dateSelected(orderDate) {
    this.selectedOrderDate = orderDate.actualDate;
    this.isExistingMealPlan = false;
    if (new Date(this.selectedOrderDate) <= new Date() || orderDate.greyedOut) {
      this.generalService.showNotification(
        'Invalid date',
        'Please select a future date or a date with an existing meal plan.'
      );
      return false;
    } else {
      if (orderDate.orderExists) {
        this.isExistingMealPlan = true;
        this.generalService.currentChildScreen = 'Order summary';

        this.selectedItems = await this.apiServices.getMealPlanItemsForDate(
          this.selectedStudent.studentId,
          moment(this.selectedOrderDate).format('YYYY-MM-DD')
        );
      } else {
        if (this.timeSlots.length > 0) {
          this.generalService.currentChildScreen = 'Time selection';
        } else {
          this.generalService.currentChildScreen = 'Category selection';
        }
      }
    }
  }

  async mealPlanSelected(mealPlan) {
    this.isExistingMealPlan = true;
    this.generalService.currentChildScreen = 'Order summary';
    this.selectedMealPlanId = mealPlan.mealPlanId;
    this.selectedOrderDate = mealPlan.orderDate;
    this.selectedItems = await this.apiServices.getMealPlanItems(
      mealPlan.mealPlanId
    );
  }

  async getTimeSlots() {
    this.timeSlots = await this.apiServices.getMealPlanningTimeSlots();
  }

  async timeSelected(timeSlot) {
    this.selectedTimeSlot = timeSlot;
    this.generalService.currentChildScreen = 'Category selection';
  }

  handleRemoveMealPlanItem() {
    //this.selectedItems = await this.apiServices.removeMealPlanItem(); //TODO
  }

  categorySelected(category) {
    this.selectedCategory = category;
    this.categoryName = category.description;
    this.generalService.currentChildScreen = 'Item selection';
    this.getCategoryItems(category);
  }

  async getCategoryItems(category) {
    this.categoryItems = await this.apiServices.getCategoryItems(category.id);
    this.generateQtyValues();
  }

  generateQtyValues() {
    this.categoryItems.map((item) => {
      item.qty = 0;
      for (var i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].id === item.id) {
          item.qty = this.selectedItems[i].qty;
        }
      }
    });
    this.countSelectedItems();
  }

  countSelectedItems() {
    this.selectedItemCount = 0;
    for (var i = 0; i < this.selectedItems.length; i++) {
      this.selectedItemCount += this.selectedItems[i].qty;
    }
  }

  itemAdded(item) {
    let itemIsInList = false;
    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.selectedItems[i].id === item.id) {
        itemIsInList = true;
        this.selectedItems[i].qty++;
      }
    }
    if (!itemIsInList) {
      this.selectedItems.push({
        id: item.id,
        description: item.description,
        price: item.price,
        qty: 1,
      });
    }
    this.generateQtyValues();
  }

  itemRemoved(item) {
    let itemInList = this.selectedItems.find(
      (listItem) => listItem.id === item.id
    );
    itemInList.qty--;
    const listItemIndex = this.selectedItems.findIndex(
      (listItem) => listItem.id == item.id
    );
    if (itemInList.qty === 0) {
      this.selectedItems.splice(listItemIndex, 1);
    }
    this.generateQtyValues();
  }

  handleCreateNewOrderClicked() {
    this.generalService.currentChildScreen = 'Student selection';
    this.selectedItems = [];
    this.selectedItemCount = 0;
  }

  handleBackClicked(previousScreen) {
    this.generalService.currentChildScreen = previousScreen;
    this.categoryItems = [];
  }

  async handleCloseClicked() {
    this.generalService.currentChildScreen = 'Index';
    this.selectedMealPlanId = 0;
    this.getRecentMealPlans();
  }

  handleCartClicked() {
    this.generalService.currentChildScreen = 'Checkout';
  }

  handleCancelOrderClicked(mealPlanId) {
    this.generalService
      .confirmDialog(
        'Cancel order',
        'Are you sure you want to cancel this meal plan?',
        'Yes'
      )
      .subscribe(async (response) => {
        if (response) {
          const result = await this.apiServices.DeleteMealPlan(mealPlanId);
          console.log(result);
          if (result == true) {
            this.getRecentMealPlans();
            this.getParentDetails();
            this.generalService.showNotification(
              'Order cancelled',
              'You have successfully cancelled the selected order, the funds have been refunded into your parent account.'
            );
            this.generalService.currentChildScreen = 'Index';
            this.selectedMealPlanId = 0;
          } else {
            this.generalService.showNotification(
              "Order can't be deleted",
              "This order can't be deleted as it has already been collected."
            );
          }
        }
      });
  }

  async handleFinishClicked(notes) {
    console.log(notes);
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    this.orderNotes = notes;
    this.orderTotal = await this.calculateOrderValue();
    this.generalService.currentChildScreen = 'Payment options';

    this.isCurrentRequest = false;
  }

  async getRecentMealPlans() {
    this.recentMealPlans = await this.apiServices.getRecentMealPlans();
  }

  async getParentDetails() {
    await this.apiServices.getParentDetails();
    this.parentDetails = this.generalService.parentDetails;
  }

  async getCategories() {
    this.categories = await this.apiServices.getCategories();
  }

  async getOrderDays() {
    this.orderDays = await this.apiServices.getOrderDays(
      moment(new Date()).format('yyyy-MM-DD'),
      0
    );
  }

  async getStudents() {
    this.students = await this.apiServices.getStudents();
  }

  async finaliseMealPlan(mealPlanDto) {
    if (this.timeSlots.length > 0) {
      mealPlanDto.timeSlot = this.selectedTimeSlot.id;
    }
    if (this.orderNotes) {
      mealPlanDto.notes = this.orderNotes;
    }
    const createMealPlanResult = await this.apiServices.CreateMealPlan(
      mealPlanDto
    );
    if (createMealPlanResult) {
      this.generalService.currentChildScreen = 'Order summary';
      this.getRecentMealPlans();
      this.getOrderDays();
    } else {
      alert('Insufficient funds');
    }
  }

  async structureMealPlanDTO(paymentSourceId) {
    let mealPlan = {
      orderDate: moment(this.selectedOrderDate).format('yyyy-MM-DD'),
      studentId: this.selectedStudent.studentId,
      orderTotal: await this.calculateOrderValue(),
      items: this.selectedItems,
      paymentSource: paymentSourceId,
    };
    return mealPlan;
  }

  async calculateOrderValue() {
    let orderValue = 0;
    for (var i = 0; i < this.selectedItems.length; i++) {
      orderValue += this.selectedItems[i].price * this.selectedItems[i].qty;
    }

    return orderValue;
  }

  async handlePayFromParentAccount() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    if (this.parentDetails.balance >= (await this.calculateOrderValue())) {
      const mealPlan = await this.structureMealPlanDTO(1);
      await this.finaliseMealPlan(mealPlan);
      await this.apiServices.getParentDetails();
    } else {
      alert(
        'There are insufficient funds in ' + 'your account for this meal plan'
      );
    }

    this.isCurrentRequest = false;
  }

  async handlePayByInstantDeposit() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    localStorage.setItem(
      'mealPlanDetails',
      JSON.stringify(await this.structureMealPlanDTO(1))
    );
    const paymentDetails = await this.apiServices.initiatePayment(
      await this.calculateOrderValue(),
      false,
      'mp'
    );
    this.cardRequestForm.setValue({
      PAY_REQUEST_ID: [paymentDetails.paymentId],
      CHECKSUM: [paymentDetails.checksum],
    });
    this.paymentForm.nativeElement.submit();
    this.isCurrentRequest = false;
  }

  async handlePayFromStudentAccount() {
    if (this.selectedStudent.balance >= (await this.calculateOrderValue())) {
      const mealPlan = await this.structureMealPlanDTO(2);
      await this.finaliseMealPlan(mealPlan);
      await this.getStudents();
    } else {
      alert(
        'There are insufficient funds in ' +
          this.selectedStudent.name +
          "'s account for this meal plan"
      );
    }
  }
}
