<div
  class="portal-container"
  [style.minHeight.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading" *ngIf="!isAddNew">Profile Settings</div>
  <div class="portal-heading" *ngIf="isAddNew">Add new parent</div>
  <div class="portal-subheading" *ngIf="!isAddNew">
    View, edit details or add a new parent
  </div>
  <div class="portal-subheading" *ngIf="isAddNew">
    Please enter in parent detials
  </div>
  <div class="details-container" *ngFor="let parent of allParentsDetails">
    <app-parent-details
      *ngIf="!isAddNew"
      [parent]="parent"
      [isMobile]="isMobile"
    ></app-parent-details>
    <app-new-parent *ngIf="isAddNew" [isMobile]="isMobile"></app-new-parent>
  </div>
  <div class="add-new-parent-button" *ngIf="false">
    <mat-icon
      class="add-parent-icon"
      aria-hidden="false"
      aria-label="Example home icon"
      *ngIf="!isAddNew"
      >add_circle</mat-icon
    >
    <div
      class="add-parent-icon-text"
      *ngIf="!isAddNew"
      (click)="isAddNew = true"
    >
      Add new parent
    </div>
    <div
      class="portal-button back-button"
      *ngIf="isAddNew"
      (click)="isAddNew = false"
    >
      Back
    </div>
  </div>
</div>
