<div
  class="portal-container"
  [style.minHeight.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading">Student details</div>
  <div class="portal-subheading">
    Here are {{ currentStudent.basicDetails.name }}'s details
  </div>
  <div class="details-container">
    <div class="top-container" [formGroup]="studentDetail">
      <div class="student-picture-container">
        <div class="student-picture-parent">
          <img
            src="{{ currentStudent.basicDetails.picture }}"
            class="student-picture"
            draggable="false"
          />
          <div class="balance-box">
            <div class="balance-box-child">
              Balance: R{{ currentStudent.basicDetails.balance }}
            </div>
          </div>
        </div>
        <div class="account-status-text-container">
          <div class="account-status-text-heading">Account status:</div>
          <div class="account-status-text">
            {{ currentStudent.basicDetails.status }}
          </div>
        </div>
      </div>
      <div class="details-container">
        <div class="details-heading-container">
          <div class="details-heading">
            {{ currentStudent.basicDetails.name }} {{ currentStudent.surname }}
          </div>
          <div
            class="details-heading-last-used-container"
            *ngIf="currentStudent.basicDetails?.lastUsed"
          >
            <div class="details-heading-last-used-heading">Last used -</div>
            <div class="details-heading-last-used-text">
              {{ currentStudent.basicDetails.lastUsed }}
            </div>
          </div>
        </div>
        <div class="details-row-container">
          <div class="details-row-text" *ngIf="!editName">
            Name: {{ currentStudent.basicDetails.name }}
          </div>
          <div class="details-row-text" *ngIf="editName">
            <input
              class="text-field-details"
              type="text"
              autocomplete="off"
              formControlName="name"
              placeholder="Enter Name"
            />
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="!editName"
            (click)="editName = !editName"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >edit</mat-icon
            >
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="editName"
            (click)="saveStudent(); editName = !editName"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >save</mat-icon
            >
          </div>
        </div>
        <div class="details-row-container">
          <div class="details-row-text" *ngIf="!editSurname">
            Surname: {{ currentStudent.surname }}
          </div>
          <div class="details-row-text" *ngIf="editSurname">
            <input
              class="text-field-details"
              type="text"
              autocomplete="off"
              formControlName="surname"
              placeholder="Enter Surname"
            />
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="!editSurname"
            (click)="editSurname = !editSurname"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >edit</mat-icon
            >
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="editSurname"
            (click)="saveStudent(); editSurname = !editSurname"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >save</mat-icon
            >
          </div>
        </div>
        <div class="details-row-container">
          <div class="details-row-text" *ngIf="!editGrade">
            Grade: {{ currentStudent.grade }}
          </div>
          <div class="details-row-text" *ngIf="editGrade">
            <input
              class="text-field-details"
              type="text"
              autocomplete="off"
              formControlName="grade"
              placeholder="Enter Grade"
            />
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="!editGrade"
            (click)="editGrade = !editGrade"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >edit</mat-icon
            >
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="editGrade"
            (click)="saveStudent(); editGrade = !editGrade"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >save</mat-icon
            >
          </div>
        </div>
        <div class="details-row-container">
          <div class="details-row-text" *ngIf="!editDailyLimit">
            Daily limit: {{ currentStudent.spendLimit }}
          </div>

          <div class="details-row-text" *ngIf="editDailyLimit">
            <input
              class="text-field-details"
              type="text"
              autocomplete="off"
              formControlName="dailyLimit"
              placeholder="Enter Name"
            />
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="!editDailyLimit"
            (click)="editDailyLimit = !editDailyLimit"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >edit</mat-icon
            >
          </div>
          <div
            class="details-row-edit-button"
            *ngIf="editDailyLimit"
            (click)="saveStudent(); editDailyLimit = !editDailyLimit"
          >
            <mat-icon
              class="edit-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              >save</mat-icon
            >
          </div>
        </div>
        <div class="details-row-container">
          <div class="details-row-text">
            Card number: {{ currentStudent.keycardId }}
          </div>
        </div>
      </div>
    </div>
    <div class="action-buttons-container">
      <div class="action-button" (click)="quickTopupClick()">
        <div class="action-button-icon-box">
          <mat-icon
            class="action-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >account_balance_wallet</mat-icon
          >
        </div>
        <div class="action-button-text">Topup</div>
      </div>
      <div class="action-button" (click)="restrictItemsClick()">
        <div class="action-button-icon-box">
          <mat-icon
            class="action-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >remove_circle</mat-icon
          >
        </div>
        <div class="action-button-text">Restrict items</div>
      </div>
      <div
        class="action-button"
        (click)="suspendStudentClick()"
        *ngIf="currentStudent.basicDetails.status === 'Active'"
      >
        <div class="action-button-icon-box">
          <mat-icon
            class="action-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >pause</mat-icon
          >
        </div>
        <div class="action-button-text">Suspend</div>
      </div>
      <div
        class="action-button"
        (click)="activateStudentClick()"
        *ngIf="currentStudent.basicDetails.status === 'Suspended'"
      >
        <div class="action-button-icon-box">
          <mat-icon
            class="action-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >play_arrow</mat-icon
          >
        </div>
        <div class="action-button-text">Activate</div>
      </div>
      <div class="action-button" (click)="viewStatsClick()">
        <div class="action-button-icon-box">
          <mat-icon
            class="action-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >bar_chart</mat-icon
          >
        </div>
        <div class="action-button-text">View stats</div>
      </div>
      <div class="action-button" (click)="orderReplacementCardClick()">
        <div class="action-button-icon-box">
          <mat-icon
            class="action-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >payment</mat-icon
          >
        </div>
        <div class="action-button-text">Replace card</div>
      </div>
    </div>
  </div>
</div>
