import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { WebsiteComponent } from './website/website.component';
import { FaqComponent } from './website/faq/faq.component';
import { TermsandconditionsComponent } from './website/termsandconditions/termsandconditions.component';
import { PrivacyComponent } from './website/privacy/privacy.component';
import { PortalComponent } from './portal/portal.component';
import { ValidatePaymentComponent } from './portal/deposit-funds/validate-payment/validate-payment.component';
import { CompleteMealPlanComponent } from './portal/meal-planning/complete-meal-plan/complete-meal-plan.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { ReplacementCardOrderCompleteComponent } from './portal/students/replacement-card-order-complete/replacement-card-order-complete.component';
import { ServiceFeePaymentSuccessfulComponent } from './portal/service-fee-payment-successful/service-fee-payment-successful.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';

const routes: Routes = [
  { path: '', component: WebsiteComponent },
  { path: 'home', component: WebsiteComponent },
  { path: 'about', component: WebsiteComponent },
  { path: 'contact', component: WebsiteComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'terms-and-conditions', component: WebsiteComponent },
  { path: 'privacy', component: WebsiteComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'register', component: WebsiteComponent },
  { path: 'portal', component: PortalComponent, canActivate: [AuthGuard] },
  {
    path: 'payment',
    component: ValidatePaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-order-complete',
    component: ReplacementCardOrderCompleteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service-fee-payment-successful',
    component: ServiceFeePaymentSuccessfulComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'completemealplan',
    component: CompleteMealPlanComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    NgxHotjarModule.forRoot('2684625'),
    NgxHotjarRouterModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
