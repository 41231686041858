import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { Router } from '@angular/router';
import { ParentDetails } from 'src/app/Models/ParentDetails';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InactiveModalComponent } from '../inactive-modal/inactive-modal.component';
import { ApiServicesService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-portal-menu-bar',
  templateUrl: './portal-menu-bar.component.html',
  styleUrls: ['./portal-menu-bar.component.scss'],
})
export class PortalMenuBarComponent implements OnInit, AfterViewInit {
  constructor(
    public generalService: GeneralService,
    private router: Router,
    private apiServices: ApiServicesService,
    public matDialog: MatDialog
  ) {}

  @Input() isMobile: boolean;
  parentName: string;
  parentLastname: string;
  parentInitial: string;
  isSeeMore = false;
  students = [];
  isInactive = false;

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.parentInitial =
      this.generalService.parentDetails?.name.charAt(0).toUpperCase() +
      this.generalService.parentDetails?.surname.charAt(0).toUpperCase();
  }

  async changeScreen(screen, childScreen) {
    this.isInactive = false;
    if (screen === 'Topup' || screen === 'Deposit funds') {
      this.students = await this.apiServices.getStudents();
      this.students.forEach((student) => {
        if (student.status === 'Inactive') {
          this.openInactiveModal();
          this.isInactive = true;
        }
      });
    }

    if (!this.isInactive) {
      this.generalService.currentScreen = screen;
      this.generalService.currentChildScreen = childScreen;
      if (this.router.url != '/portal') {
        this.router.navigate(['/', 'portal']);
      }
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/', 'home']);
  }

  openInactiveModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '500px';
    dialogConfig.width = '700px';
    const modalDialog = this.matDialog.open(
      InactiveModalComponent,
      dialogConfig
    );
  }
}
