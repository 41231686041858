<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <app-select-deposit-option
    *ngIf="generalService.currentChildScreen == 'Select option'"
    [screenHeight]="screenHeight"
    [screenWidth]="screenWidth"
    [isInstantPaymentAllowed]="isInstantPaymentAllowed"
  ></app-select-deposit-option>
  <app-bank-deposit
    *ngIf="generalService.currentChildScreen == 'Bank deposit'"
    [screenHeight]="screenHeight"
    [screenWidth]="screenWidth"
  ></app-bank-deposit>
  <app-instant-deposit
    *ngIf="
      generalService.currentChildScreen == 'Instant deposit' &&
      isInstantPaymentAllowed
    "
    [screenHeight]="screenHeight"
    [screenWidth]="screenWidth"
  ></app-instant-deposit>
</div>
