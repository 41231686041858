<div class="portal-heading">Activate student lunchcard</div>
<div class="portal-subheading">
  A service fee is payable for the use of the lunchcard service.
  <br />*Payment is only required for the first student on your account, all
  other siblings are free.
</div>

<div class="portal-heading-text">Please select a method of payment below</div>

<div class="deposit-options-parent">
  <div class="deposit-options-box">
    <div class="deposit-option" (click)="annualPayment()">
      <img
        class="student-small-picture"
        src="../../../../../assets/annualpaymentbtn.png"
        width="200"
      />
      <div class="deposit-option-text">
        <strong>R{{ apiServices.schoolDetails.annualFee }} per year</strong
        ><br />{{ discount }}% discount
      </div>
    </div>

    <div class="deposit-option" (click)="monthlyPayment()">
      <img
        class="student-small-picture"
        src="../../../../../assets/monthlypaymentbtn.png"
        width="200"
      />
      <div class="deposit-option-text">
        <strong>R{{ apiServices.schoolDetails.monthlyFee }} per month</strong>
      </div>
    </div>
  </div>
</div>

<div class="portal-button med-width" (click)="cancelClicked()">Cancel</div>
