<div class="main-container">
  <div class="heading">
    <div class="heading-text">Lunchcard (Pty) Ltd</div>
    <div class="sub-heading">Terms and conditions of Lunchcard (Pty) Ltd</div>
  </div>
  <div class="text-container">
    <h2>Definitions</h2>
    <p>
      "This Company" means Lunchcard (Pty) Ltd. <br />
      "Us" means Lunchcard (Pty) Ltd.<br />
      "We" means Lunchard (Pty) Ltd.<br />
      "Client" means you.<br />
      "School" means the school which your child attends.<br />
      "Tuck-shop owner" means the owner of the Tuck-shop at your child's
      school.<br />
    </p>
    <h2>Detailed description of goods and/or services</h2>
    <p>
      Lunchcard is a business in the information technology industry that
      provides software-based services to schools, parents, and students. As a
      registered parent, you are able to allocate funds to your child's
      lunchcard in order for them to make purchases at the school tuckshop.
    </p>
    <h2>Delivery policy</h2>
    <p>
      Subject to availability and receipt of payment, requests for replacement
      cards will be processed within 2 days and delivery confirmed by way of
      email notification once delivered to the school.
    </p>
    <h2>Privacy Statement</h2>
    <p>
      We are committed to protecting your privacy. Authorised employees within
      the company on a need to know basis only use any information collected
      from individual customers. We constantly review our systems and data to
      ensure the best possible service to our customers. Parliament has created
      specific offences for unauthorised actions against computer systems and
      data. We will investigate any such actions with a view to prosecuting
      and/or taking civil proceedings to recover damages against those
      responsible.
    </p>
    <h2>Confidentiality</h2>
    <p>
      Client records are regarded as confidential and therefore will not be
      divulged to any third party, other than Lunchcard (Pty) Ltd if legally
      required to do so to the appropriate authorities. Clients are requested to
      retain copies of any literature issued in relation to the provision of our
      services. Where appropriate, we shall issue Client’s with appropriate
      written information, handouts or copies of records as part of an agreed
      contract, for the benefit of both parties. We will not sell, share, or
      rent your personal information to any third party or use your e-mail
      address for unsolicited mail. Any emails sent by this Company will only be
      in connection with the provision of agreed services and products.
    </p>
    <h1>Disclaimer</h1>
    <h2>Exclusions and Limitations</h2>
    <p>
      The information on this web site is provided on an "as is" basis. To the
      fullest extent permitted by law, this Company: Excludes all
      representations and warranties relating to this website and its contents
      or which is or may be provided by any affiliates or any other third party,
      including in relation to any inaccuracies or omissions in this website
      and/or the Company’s literature; and excludes all liability for damages
      arising out of or in connection with your use of this website. This
      includes, without limitation, direct loss, loss of business or profits
      (whether or not the loss of such profits was foreseeable, arose in the
      normal course of things or you have advised this Company of the
      possibility of such potential loss), damage caused to your computer,
      computer software, systems and programs and the data thereon or any other
      direct or indirect, consequential and incidental damages.
    </p>
    <h2>Cancellation Policy</h2>
    <p>
      Cancellation of the subscription service can be made by means of
      notification by E-mail to info (at) lunchcard.co.za. Cancellation requests
      can be made at any time during the month but will only be processed on the
      1st (first) day of the month after the monthly subscription fee has been
      deducted.
    </p>
    <h2>Termination of Agreements and Refunds Policy</h2>
    <p>
      Both the Client and ourselves have the right to terminate any Services
      Agreement for any reason, including the ending of services that are
      already underway. No refunds shall be offered, where a service is deemed
      to have begun and is, for all intents and purposes, underway.
      <br />
      Clients will be entitled to a full refund on replacement card orders
      within 30 days should they not recieve their ordered card.
    </p>
    <h2>Customer Privacy policy</h2>
    <p>
      Lunchcard shall take all reasonable steps to protect the personal
      information of users. For the purpose of this clause, "personal
      information" shall be defined as detailed in the Promotion of Access to
      Information Act 2 of 2000 (PAIA). The PAIA may be downloaded from:
      http://www.polity.org.za/attachment.php?aa_id=3569. Customer details
      separate from card details Customer details will be stored by Lunchcard
      (Pty) Ltd separately from card details which are entered by the client on
      DPO PayGate’s secure site. For more detail on DPO PayGate refer to
      www.paygate.co.za. Merchant Outlet country and transaction currency The
      merchant outlet country at the time of presenting payment options to the
      cardholder is South Africa. Transaction currency is South African Rand
      (ZAR).
    </p>
    <h2>Country of domicile</h2>
    <p>
      This website is governed by the laws of South Africa and Lunchcard (Pty)
      Ltd chooses as its domicilium citandi et executandi for all purposes under
      this agreement, whether in respect of court process, notice, or other
      documents or communication of whatsoever nature, 5 Concourse Crescent,
      Lone Hill.
    </p>
    <h2>Responsibility</h2>
    <p>
      Lunchcard (Pty) Ltd takes responsibility for all aspects relating to the
      transaction when ordering a replacement card on this website, customer
      service and support, dispute resolution and delivery of goods.
    </p>
    <h2>Variation</h2>
    <p>
      Lunchcard (Pty) Ltd may, in its sole discretion, change this agreement or
      any part thereof at any time without notice.
    </p>
    <h2>Company information</h2>
    <p>
      This website is run by Lunchcard (Pty) Ltd based in South Africa trading
      as Lunchcard and with registration number 2013 / 065812 / 07
    </p>
    <h2>Lunchcard's contact details</h2>
    <p>
      Company Physical Address: 5 Concourse Crescent, Lone Hill Email: info (at)
      lunchcard.co.za Telephone: 011 234 4447
    </p>
    <h2>Availability</h2>
    <p>
      You are solely responsible for evaluating the fitness for a particular
      purpose of any downloads, programs and text available through this site.
      Redistribution or republication of any part of this site or its content is
      prohibited, including such by framing or other similar or any other means,
      without the express written consent of the Company. The Company does not
      warrant that the service from this site will be uninterrupted, timely or
      error free, although it is provided to our best ability. By using this
      service you thereby indemnify this Company, its employees, agents and
      affiliates against any loss or damage, in whatever manner, howsoever
      caused.
    </p>
    <h2>Log Files</h2>
    <p>
      We use IP addresses to analyse trends, administer the site, track user’s
      movement, and gather broad demographic information for aggregate use. For
      systems administration, detecting usage patterns and troubleshooting
      purposes, our web servers automatically log standard access information
      including browser type, access times/open mail, URL requested, and
      referral URL. This information is not shared with third parties and is
      used only within this Company on a need-to-know basis. Any individually
      identifiable information related to this data will never be used in any
      way different to that stated above without your explicit permission.
    </p>
    <h2>Cookies</h2>
    <p>
      Like most interactive web sites this Company’s website [or ISP] uses
      cookies to enable us to retrieve user details for each visit. Cookies are
      used in some areas of our site to enable the functionality of this area
      and ease of use for those people visiting. Some of our affiliate partners
      may also use cookies.
    </p>
    <h2>Links to this website</h2>
    <p>
      You may not create a link to any page of this website without our prior
      written consent. If you do create a link to a page of this website you do
      so at your own risk and the exclusions and limitations set out above will
      apply to your use of this website by linking to it.
    </p>
    <h2>Links from this website</h2>
    <p>
      We do not monitor or review the content of other party’s websites which
      are linked to from this website. Opinions expressed or material appearing
      on such websites are not necessarily shared or endorsed by us and should
      not be regarded as the publisher of such opinions or material. Please be
      aware that we are not responsible for the privacy practices, or content,
      of these sites. We encourage our users to be aware when they leave our
      site & to read the privacy statements of these sites. You should evaluate
      the security and trustworthiness of any other site connected to this site
      or accessed through this site yourself, before disclosing any personal
      information to them. This Company will not accept any responsibility for
      any loss or damage in whatever manner, howsoever caused, resulting from
      your disclosure to third parties of personal information.
    </p>
    <h2>Copyright Notice</h2>
    <p>
      Copyright and other relevant intellectual property rights exists on all
      text and media relating to the Company’s services and the full content of
      this website. This Company’s logo is a registered trademark of this
      Company in South Africa and other countries.
    </p>
    <h2>Card acquiring and security</h2>
    <p>
      Card transactions will be acquired for Lunchcard (Pty) Ltd via PayGate
      (Pty) Ltd who are the approved payment gateway for all South African
      Acquiring Banks. DPO PayGate uses the strictest form of encryption, namely
      Secure Socket Layer 3 (SSL3) and no Card details are stored on the
      website. Users may go to www.paygate.co.za to view their security
      certificate and security policy.
    </p>
    <h2>Communication</h2>
    <p>
      We have several different e-mail addresses for different queries. These, &
      other contact information, can be found on our Contact Us link on our
      website or via Company literature or via the Company’s stated telephone,
      facsimile or mobile telephone numbers.
    </p>
    <h2>Force Majeure</h2>
    <p>
      Neither party shall be liable to the other for any failure to perform any
      obligation under any agreement which is due to an event beyond the control
      of such party including but not limited to any Act of God, terrorism, war,
      Political insurgence, insurrection, riot, civil unrest, act of civil or
      military authority, uprising, earthquake, flood or any other natural or
      man made eventuality outside of our control, which causes the termination
      of an agreement or contract entered into, nor which could have been
      reasonably foreseen. Any Party affected by such event shall forthwith
      inform the other Party of the same and shall use all reasonable endeavours
      to comply with the terms and conditions of any Agreement contained herein.
    </p>
    <h2>Waiver</h2>
    <p>
      Failure of either Party to insist upon strict performance of any provision
      of this or any Agreement or the failure of either Party to exercise any
      right or remedy to which it, he or they are entitled hereunder shall not
      constitute a waiver thereof and shall not cause a diminution of the
      obligations under this or any Agreement. No waiver of any of the
      provisions of this or any Agreement shall be effective unless it is
      expressly stated to be such and signed by both Parties. Neither Lunchcard
      (Pty) Ltd nor the participating school / college shall be liable for any
      damage to or loss of property nor any injury, sickness or death resulting
      in the use of the Lunchcard system.
    </p>
    <h2>Payment options accepted</h2>
    <p>
      Payment for replacement cards or service fees may be made via Visa,
      MasterCard, Diners or American Express Cards or by bank transfer into the
      (Your Company) bank account, the details of which will be provided on
      request.
    </p>
    <h2>General</h2>
    <p>
      By accessing this website and / or using our services / buying our
      products you consent to these terms and conditions and to the exclusive
      jurisdiction of the South African courts in all disputes arising out of
      such access. If any of these terms are deemed invalid or unenforceable for
      any reason (including, but not limited to the exclusions and limitations
      set out above), then the invalid or unenforceable provision will be
      severed from these terms and the remaining terms will continue to apply.
      Failure of the Company to enforce any of the provisions set out in these
      Terms and Conditions and any Agreement, or failure to exercise any option
      to terminate, shall not be construed as waiver of such provisions and
      shall not affect the validity of these Terms and Conditions or of any
      Agreement or any part thereof, or the right thereafter to enforce each and
      every provision. These Only service fees and card replacement fees are
      paid to us, any other amount paid for use with the Lunchcard service are
      paid directly to the tuck-shop owner or school and are not held by us. The
      banking details displayed on the "Deposit funds" page or provided on
      request to customers are those of the tuck-shop owner or school and are
      not ours. The user will have the ability to deposit funds into their
      lunchcard account for use with purchases of school items, services and
      consumables. No amount deposited into the user's lunchcard account will be
      refunded as any amount deposited into the bank account provided will be
      deemed as being for the purchase of goods and/or services in advance. No
      balance amount reflected anywhere on the lunchcard website/system should
      be viewed as a balance of legal tender or credit belonging to the user.
      Balance amounts reflected are solely for the purpose of indicating the
      remaining value of goods or services to be collected by the user or the
      representative of the user. The Lunchcard system is a management tool for
      goods / services already paid for by the customer and as such, no refunds
      will be provided for any balance amount reflected on the lunchcard
      website/system. By using this service you also confirm that you are the
      guardian or parent of any child linked to your Lunchcard account and that
      you consent to their basic particulars and photograph being used by us for
      the sole purpose of use with the Lunchcard service. No personal
      information of any child, parent, or guardian obtained for use with the
      Lunchcard service will be disclosed to external companies without their or
      parent / guardian's consent. Deposits into the user's lunchcard account
      can be made by means of EFT (Electronic Funds Transfer). Any acceptance on
      this website by the user which authorises deductions from the user's bank
      account constitutes their written consent for deduction of these monies.
      This acceptance for deductions on this website may be used in a court of
      law as proof of the user's acceptance of these deductions from the user's
      bank account.
    </p>
    <h2>Notification of Changes</h2>
    <p>
      The Company reserves the right to change these conditions from time to
      time as it sees fit and your continued use of the site will signify your
      acceptance of any adjustment to these terms. If there are any changes to
      our privacy policy, we will announce that these changes have been made on
      our home page and on other key pages on our site. You are therefore
      advised to re-read this statement on a regular basis These terms and
      conditions form part of the Agreement between the Client and ourselves.
      Your accessing of this website and/or undertaking of an Agreement
      indicates your understanding, agreement to and acceptance, of the
      Disclaimer Notice and the full Terms and Conditions contained herein. Your
      statutory Consumer Rights are unaffected.
    </p>
  </div>
</div>
<app-footer [screenHeight]="screenHeight"></app-footer>
