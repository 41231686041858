<div class="content" [style.height.px]="screenHeight * 0.33">
  <div class="links-box">
    <div class="link-item" (click)="handleRouteClicked('home')">Home</div>
    <div class="link-item" (click)="handleRouteClicked('contact')">Contact</div>
    <div class="link-item" (click)="handleRouteClicked('about')">About</div>
    <div class="link-item" *ngIf="false" (click)="handleRouteClicked('faq')">
      FAQ
    </div>
  </div>
  <div class="links-box lower-links-box">
    <div class="link-item">
      <img src="{{ logoPath }}" [height]="screenHeight * 0.015" />
    </div>
    <div class="link-item" (click)="handleRouteClicked('privacy')">
      Privacy Policy
    </div>
    <div class="link-item" (click)="handleRouteClicked('terms-and-conditions')">
      Terms and Conditions
    </div>
  </div>
  <div class="footer-text">Copyright &copy; Lunchcard (Pty) Ltd {{ currentYear }}</div>
</div>
