<app-meal-planning-index
  *ngIf="generalService.currentChildScreen == 'Index'"
  [screenHeight]="screenHeight"
  [recentMealPlans]="recentMealPlans"
  [isMealPlanningAllowed]="isMealPlanningAllowed"
  [students]="students"
  (handleCreateNewOrderClicked)="handleCreateNewOrderClicked()"
  (dateSelected)="dateSelected($event)"
  (mealPlanSelected)="mealPlanSelected($event)"
></app-meal-planning-index>
<app-meal-planning-student-selection
  *ngIf="generalService.currentChildScreen == 'Student selection'"
  (studentSelected)="studentSelected($event)"
  [students]="students"
  [screenHeight]="screenHeight"
  [isMobile]="isMobile"
></app-meal-planning-student-selection>
<app-meal-planning-date-selection
  *ngIf="generalService.currentChildScreen == 'Date selection'"
  [orderDays]="orderDays"
  [screenHeight]="screenHeight"
  [selectedStudent]="selectedStudent"
  (dateSelected)="dateSelected($event)"
></app-meal-planning-date-selection>
<app-meal-planning-time-selection
  *ngIf="generalService.currentChildScreen == 'Time selection'"
  [screenHeight]="screenHeight"
  [selectedStudent]="selectedStudent"
  (handleTimeSlotClicked)="timeSelected($event)"
  [timeSlots]="timeSlots"
></app-meal-planning-time-selection>
<app-meal-planning-categories
  *ngIf="generalService.currentChildScreen == 'Category selection'"
  [screenHeight]="screenHeight"
  [selectedStudent]="selectedStudent"
  [categories]="categories"
  [selectedItemCount]="selectedItemCount"
  (categorySelected)="categorySelected($event)"
  (handleCartClicked)="handleCartClicked()"
></app-meal-planning-categories>
<app-meal-planning-item-selection
  *ngIf="generalService.currentChildScreen == 'Item selection'"
  [screenHeight]="screenHeight"
  [categoryItems]="categoryItems"
  [selectedItemCount]="selectedItemCount"
  [categoryName]="categoryName"
  [selectedStudent]="selectedStudent"
  (itemAdded)="itemAdded($event)"
  (itemRemoved)="itemRemoved($event)"
  (handleBackClicked)="handleBackClicked($event)"
  (handleCartClicked)="handleCartClicked()"
></app-meal-planning-item-selection>
<app-meal-planning-order-checkout
  *ngIf="generalService.currentChildScreen == 'Checkout'"
  [screenHeight]="screenHeight"
  [selectedStudent]="selectedStudent"
  [selectedItems]="selectedItems"
  (handleBackClicked)="handleBackClicked($event)"
  (itemAdded)="itemAdded($event)"
  (itemRemoved)="itemRemoved($event)"
  (handleFinishClicked)="handleFinishClicked($event)"
>
</app-meal-planning-order-checkout>
<app-meal-planning-payment-options
  *ngIf="generalService.currentChildScreen == 'Payment options'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [selectedStudent]="selectedStudent"
  [parentDetails]="parentDetails"
  [orderTotal]="orderTotal"
  (handlePayFromStudentAccount)="handlePayFromStudentAccount()"
  (handlePayFromParentAccount)="handlePayFromParentAccount()"
  (handlePayByInstantDeposit)="handlePayByInstantDeposit()"
  (handleBackClicked)="handleBackClicked($event)"
  [isMobile]="isMobile"
>
</app-meal-planning-payment-options>
<app-meal-planning-order-summary
  *ngIf="generalService.currentChildScreen == 'Order summary'"
  [screenHeight]="screenHeight"
  [selectedStudent]="selectedStudent"
  [selectedItems]="selectedItems"
  [selectedOrderDate]="selectedOrderDate"
  [isExistingMealPlan]="isExistingMealPlan"
  (handleRemoveMealPlanItem)="handleRemoveMealPlanItem(item)"
  (handleCloseClicked)="handleCloseClicked()"
  (handleCancelOrderClicked)="handleCancelOrderClicked($event)"
  [selectedMealPlanId]="selectedMealPlanId"
></app-meal-planning-order-summary>
<form
  #paymentForm
  novalidate
  role="form"
  action="https://secure.paygate.co.za/payweb3/process.trans"
  method="POST"
  [formGroup]="cardRequestForm"
>
  <input type="hidden" name="PAY_REQUEST_ID" formControlName="PAY_REQUEST_ID" />
  <input type="hidden" name="CHECKSUM" formControlName="CHECKSUM" />
</form>
