import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParentDetails } from 'src/app/Models/ParentDetails';
import { ApiServicesService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-new-parent',
  templateUrl: './new-parent.component.html',
  styleUrls: ['./new-parent.component.scss'],
})
export class NewParentComponent implements OnInit {
  @Input() isMobile: number;
  public parentDetail: FormGroup;
  public parent: any;
  public isSubmitted = false;
  public isCurrentRequest = false;

  constructor(
    private fb: FormBuilder,
    private apiServices: ApiServicesService
  ) {}

  ngOnInit(): void {
    this.parentDetail = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      isLowBalanceNotifications: [''],
      isAutoTopup: [''],
      balanceNotificationAmount: ['', Validators.required],
    });
  }

  async saveParent() {
    this.isSubmitted = true;
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    this.parent = {
      name: this.parentDetail.get('name').value,
      surname: this.parentDetail.get('surname').value,
      emailAddress: this.parentDetail.get('email').value,
      cellphoneNumber: this.parentDetail.get('phoneNumber').value,
      lowBalanceNotifications: this.parentDetail.get(
        'isLowBalanceNotifications'
      ).value,
      autoTopup: this.parentDetail.get('isAutoTopup').value,
      balanceNotificationAmount: this.parentDetail.get(
        'balanceNotificationAmount'
      ).value,
    };

    const parentUpdateResult = await this.apiServices.addParent(this.parent);
    this.isCurrentRequest = false;
  }
}
