import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { ParentDetails } from 'src/app/Models/ParentDetails';
import { ApiServicesService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-meal-planning-payment-options',
  templateUrl: './meal-planning-payment-options.component.html',
  styleUrls: ['./meal-planning-payment-options.component.scss'],
})
export class MealPlanningPaymentOptionsComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;
  @Input() selectedStudent: BasicStudent;
  @Input() parentDetails: ParentDetails;
  @Input() orderTotal;
  @Output() handlePayFromStudentAccount = new EventEmitter();
  @Output() handlePayFromParentAccount = new EventEmitter();
  @Output() handlePayByInstantDeposit = new EventEmitter();
  @Output() handleBackClicked = new EventEmitter();

  public isInstantPaymentAllowed: boolean = true;
  constructor(private apiService: ApiServicesService) {}

  ngOnInit(): void {
    this.checkIfInstantPayment();
  }

  async checkIfInstantPayment() {
    const result = await this.apiService.getSchoolDetails();
    if (!result.cardPayments) {
      this.isInstantPaymentAllowed = false;
    }
  }

  payByInstantPayment() {
    this.handlePayByInstantDeposit.emit();
  }

  payFromStudentAccountClicked() {
    this.handlePayFromStudentAccount.emit();
  }

  payFromParentAccountClicked() {
    this.handlePayFromParentAccount.emit();
  }

  backClicked() {
    this.handleBackClicked.emit('Checkout');
  }
}
