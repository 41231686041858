<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading">Create new order</div>
  <div class="portal-subheading">
    Select the student you would like to order for<br />
  </div>
  <div class="students-container">
    <div *ngFor="let student of students">
      <div
        class="student-picture-parent clickable"
        (click)="selectStudent(student)"
      >
        <img
          src="{{ student.picture }}"
          class="student-picture"
          draggable="false"
        />
      </div>
      <div class="student-name-text">
        {{ student.name }}
      </div>
    </div>
  </div>
</div>
