<app-student-selection
  *ngIf="generalService.currentChildScreen === 'Student selection'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [isMobile]="isMobile"
  [students]="students"
  (quickTopup)="quickTopup($event)"
  (selectStudent)="selectStudent($event)"
></app-student-selection>

<app-view-student
  *ngIf="generalService.currentChildScreen === 'View student'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [currentStudent]="currentStudent"
  (quickTopup)="quickTopup($event)"
  (selectStudent)="selectStudent($event)"
  (suspendStudent)="suspendStudent($event)"
  (activateStudent)="activateStudent($event)"
  (restrictItems)="restrictItems($event)"
  (viewReports)="viewReports($event)"
  (orderReplacementCard)="orderReplacementCard($event)"
  [isMobile]="isMobile"
></app-view-student>

<app-add-student
  *ngIf="generalService.currentChildScreen === 'Add Student'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [students]="students"
  (getStudents)="getStudents()"
  [isMobile]="isMobile"
></app-add-student>

<app-set-restrictions
  *ngIf="generalService.currentChildScreen === 'Student Restrictions'"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [currentStudent]="currentStudent"
  (selectStudent)="selectStudent($event)"
  [isMobile]="isMobile"
></app-set-restrictions>

<form
  #paymentForm
  novalidate
  role="form"
  action="https://secure.paygate.co.za/payweb3/process.trans"
  method="POST"
  [formGroup]="cardRequestForm"
>
  <input type="hidden" name="PAY_REQUEST_ID" formControlName="PAY_REQUEST_ID" />
  <input type="hidden" name="CHECKSUM" formControlName="CHECKSUM" />
</form>
