import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent implements OnInit {
  @Output() emitNavClick = new EventEmitter();
  public currentUrl: string;

  constructor(private router: Router) {}


  ngOnInit(): void {
  this.currentUrl = this.router.url;
  }

  handleRouteClicked(route: string) {

    if (route === 'old') {
      const url = 'https://old.lunchcard.co.za';
      window.open(url, "_blank");
      return;
    }

    if (this.currentUrl === '/privacy' ||
      this.currentUrl === '/terms-and-conditions' || 
      route === 'login' ||
      route === 'register') {
      this.router.navigate(['/', route]);
    } else {
      this.emitNavClick.emit(route);
    }
  }

}
