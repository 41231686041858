<div class="portal-container" [style.minHeight.px]="screenHeight - 220">
  <div class="portal-heading">Order summary</div>
  <div class="portal-subheading">
    These are the details for your order
    <br />
  </div>
  <div class="checkout-header-box">
    <div>
      <svg
        class="icon-box"
        style="width: 80px; height: 80px"
        viewBox="0 0 24 24"
      >
        <path
          fill="#0095d3"
          d="M12.5,1.5C10.73,1.5 9.17,2.67 8.67,4.37C8.14,4.13 7.58,4 7,4A4,4 0 0,0 3,8C3,9.82 4.24,11.41 6,11.87V19H19V11.87C20.76,11.41 22,9.82 22,8A4,4 0 0,0 18,4C17.42,4 16.86,4.13 16.33,4.37C15.83,2.67 14.27,1.5 12.5,1.5M12,10.5H13V17.5H12V10.5M9,12.5H10V17.5H9V12.5M15,12.5H16V17.5H15V12.5M6,20V21A1,1 0 0,0 7,22H18A1,1 0 0,0 19,21V20H6Z"
        />
      </svg>
    </div>
    <div class="student-picture-box">
      <img
        class="student-small-picture"
        [src]="selectedStudent.picture"
        width="60"
      />
    </div>
  </div>
  <div class="items-box">
    <div class="item-row-box" *ngFor="let item of selectedItems">
      <div class="item-box-text">
        {{ item.description }} | R{{ item.price }}
      </div>
      <div class="item-box-buttons">
        <div
          *ngIf="item.qty > 0"
          class="item-box-remove-button"
          (click)="removeItem(item)"
        >
          <mat-icon
            class="item-box-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >remove</mat-icon
          >
        </div>
        <div class="item-box-add-button" (click)="addItem(item)">
          <mat-icon
            class="item-box-button-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >add</mat-icon
          >
        </div>
        <div *ngIf="item.qty > 0" class="item-box-item-count">
          {{ item.qty }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="checkout-notes"
    *ngIf="apiServices.schoolDetails.allowMealPlanNotes"
  >
    Enter any notes for this order below
    <input
      class="text-field-details"
      type="text"
      autocomplete="off"
      [(ngModel)]="orderNotes"
      placeholder="Notes for this order..."
    />
  </div>
  <div class="checkout-buttons-box">
    <div
      (click)="finishClicked()"
      class="portal-alternate-button"
      *ngIf="selectedItems.length > 0"
    >
      Finish
    </div>
    <div class="portal-button" (click)="backClicked()">Back</div>
  </div>
</div>
