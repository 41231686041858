import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-complete-meal-plan',
  templateUrl: './complete-meal-plan.component.html',
  styleUrls: ['./complete-meal-plan.component.scss'],
})
export class CompleteMealPlanComponent implements OnInit {
  orderComplete = false;
  screenHeight: number;
  screenWidth: number;
  orderItems = [];
  orderDate = '';

  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private route: ActivatedRoute,
    public generalService: GeneralService,
    private apiServices: ApiServicesService,
    private router: Router
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.apiServices.getParentDetails();
    if (localStorage.getItem('mealPlanDetails')) {
      this.finaliseMealPlan(localStorage.getItem('mealPlanDetails'));
    } else {
      this.generalService.showNotification(
        'Error',
        'There seems to a problem with this meal plan. If your payment was successful the funds will be allocated to your parent account.'
      );
    }
  }

  async finaliseMealPlan(mealPlanDto) {
    const createMealPlanResult = await this.apiServices.CreateMealPlan(
      mealPlanDto
    );
    localStorage.removeItem('mealPlanDetails');
    const orderDto = JSON.parse(createMealPlanResult);
    this.orderItems = orderDto.items;
    this.orderDate = orderDto.orderDate;

    if (createMealPlanResult) {
      this.orderComplete = true;
    } else {
      alert('Insufficient funds');
    }
  }

  handleCloseClicked() {
    this.generalService.currentScreen = 'Meal planning';
    this.generalService.currentChildScreen = 'Index';
    this.router.navigate(['/', 'portal']);
  }
}
