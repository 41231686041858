<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading">Payment options</div>
  <div class="portal-subheading">How do you want to pay for this order?</div>
  <div class="order-total">Order total: R{{ orderTotal }}</div>
  <div class="deposit-options-parent">
    <div
      class="deposit-options-box"
      [style.marginTop.px]="screenHeight / 2 - 300"
    >
      <div class="deposit-option" (click)="payByInstantPayment()" *ngIf="isInstantPaymentAllowed">
        <div class="deposit-option-icon">
          <mat-icon
            class="deposit-option-icon-text"
            aria-hidden="false"
            aria-label="Example home icon"
            >payment</mat-icon
          >
        </div>
        <div class="deposit-option-text">
          <strong>Instant payment</strong><br />(R10 fee)
        </div>
      </div>
      <div class="deposit-option" (click)="payFromParentAccountClicked()">
        <div class="deposit-option-icon">
          <mat-icon
            class="deposit-option-icon-text"
            aria-hidden="false"
            aria-label="Example home icon"
            >account_balance</mat-icon
          >
        </div>
        <div class="deposit-option-text">
          <strong>Parent account</strong>
          <div class="balance-text">R{{ parentDetails.balance }} available</div>
          (No fee)
        </div>
      </div>
      <div class="deposit-option" (click)="payFromStudentAccountClicked()">
        <div class="deposit-option-icon">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="graduation-cap"
            class="deposit-option-icon-text deposit-option-svg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path
              fill="currentColor"
              d="M622.34 153.2L343.4 67.5c-15.2-4.67-31.6-4.67-46.79 0L17.66 153.2c-23.54 7.23-23.54 38.36 0 45.59l48.63 14.94c-10.67 13.19-17.23 29.28-17.88 46.9C38.78 266.15 32 276.11 32 288c0 10.78 5.68 19.85 13.86 25.65L20.33 428.53C18.11 438.52 25.71 448 35.94 448h56.11c10.24 0 17.84-9.48 15.62-19.47L82.14 313.65C90.32 307.85 96 298.78 96 288c0-11.57-6.47-21.25-15.66-26.87.76-15.02 8.44-28.3 20.69-36.72L296.6 284.5c9.06 2.78 26.44 6.25 46.79 0l278.95-85.7c23.55-7.24 23.55-38.36 0-45.6zM352.79 315.09c-28.53 8.76-52.84 3.92-65.59 0l-145.02-44.55L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.18-113.47-145.03 44.56z"
            ></path>
          </svg>
        </div>
        <div class="deposit-option-text">
          <strong>{{ selectedStudent.name }}'s account</strong>
          <div class="balance-text">
            R{{ selectedStudent.balance }} available
          </div>
          (No fee)
        </div>
      </div>
    </div>
    <div class="portal-button back-botton-positioning" (click)="backClicked()">
      Back
    </div>
  </div>
</div>
