<div
  #home
  id="home"
  class="content-box"
  [style.minHeight.px]="screenHeight - 78"
>
  <div *ngIf="!isMobile" class="header" [style.height.px]="screenHeight * 0.4">
    <div
      class="header-child-box"
      [style.height.px]="screenHeight * 0.4"
      [style.marginTop.px]="screenHeight * 0.07 - 4"
      [style.width.px]="iscreenWidth * 0.58"
    >
      <div class="header-child-text">
        <div class="heading-text">Cashless schooling<br />done right</div>
        <div class="heading-sub-text">
          lunchcard is a fully comprehensive cashless <br />
          management system for schools<br /><br />
          <div
            class="more-info-button"
            *ngIf="!isShowMore"
            (click)="moreInfo(about)"
          >
            More info
          </div>
        </div>
      </div>
      <div class="lunchcard" [style.marginLeft.px]="screenWidth * 0.394">
        <img src="../../assets/lunchcard.png" [height]="screenHeight * 0.25" />
      </div>
    </div>
  </div>

  <div *ngIf="isMobile" class="mobile-header">
    <div class="mobile-header-child-box">
      <div class="header-child-text">
        <div class="mobile-heading-text">Cashless schooling done right</div>
        <div class="heading-sub-text">
          lunchcard is a fully comprehensive <br />
          cashless management system <br />
          for schools. <br />
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="mobile-lunchcard" *ngIf="isMobile">
      <img src="../../assets/lunchcard.png" [width]="screenWidth * 0.8" />
    </div>
    <div class="sub-heading-text">How does it work?</div>
  </div>

  <div class="content">
    <div class="step-boxes">
      <div
        class="step-box"
        [ngClass]="{
          'step-box-small-screen': screenWidth <= 1240 && !isMobile,
          'step-box': !isMobile
        }"
      >
        <div class="step-header">
          <div class="half-circle">
            <div class="icon-box">
              <mat-icon
                class="icon"
                aria-hidden="false"
                aria-label="Example home icon"
                >person_add</mat-icon
              >
            </div>
          </div>
          <div class="step-header-bar">Step One</div>
        </div>
        <div class="step-text">
          Register your account.<br /><br />
          Once registered, parents can link their children by their unique
          lunchcard ID's, create food restrictions, top up lunchcards, place
          orders for school items and view transaction history reports.
        </div>
      </div>
      <div
        *ngIf="!isMobile"
        class="step-spacer"
        [style.marginTop.px]="screenHeight * 0.17"
      ></div>
      <div
        class="step-box"
        [ngClass]="{
          'step-box-small-screen': screenWidth <= 1240 && !isMobile,
          'step-box': !isMobile
        }"
      >
        <div class="step-header">
          <div class="half-circle">
            <div class="icon-box">
              <mat-icon
                class="icon"
                aria-hidden="false"
                aria-label="Example home icon"
                >account_balance</mat-icon
              >
            </div>
          </div>
          <div class="step-header-bar">Step Two</div>
        </div>
        <div class="step-text">
          Make a payment<br /><br />
          A parent can now pay for school items online, schools can then release
          items when presented with a student's photo ID lunchcard.
        </div>
      </div>
      <div
        *ngIf="!isMobile"
        class="step-spacer"
        [style.marginTop.px]="screenHeight * 0.17"
      ></div>
      <div
        class="step-box"
        [ngClass]="{
          'step-box-small-screen': screenWidth <= 1240 && !isMobile,
          'step-box': !isMobile
        }"
      >
        <div class="step-header">
          <div class="half-circle">
            <div class="icon-box">
              <mat-icon
                class="icon"
                aria-hidden="false"
                aria-label="Example home icon"
                >payment</mat-icon
              >
            </div>
          </div>
          <div class="step-header-bar">Step Three</div>
        </div>
        <div class="step-text">
          Students receive a photo ID lunchcard which can be topped up using
          your credit card, via electronic funds transfer or by debit order.
        </div>
      </div>
    </div>
  </div>

  <div class="section-space"></div>

  <div #about id="about">
    <app-about
      [screenWidth]="screenWidth"
      [screenHeight]="screenHeight"
      [isMobile]="isMobile"
    ></app-about>
  </div>

  <div class="section-divider"></div>

  <div #contact id="contact">
    <app-contact
      #contact
      [screenWidth]="screenWidth"
      [screenHeight]="screenHeight"
      [isMobile]="isMobile"
    ></app-contact>
  </div>
</div>

<app-footer [screenHeight]="screenHeight"></app-footer>
