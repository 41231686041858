import { Component, OnInit, Input } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ApiServicesService } from '../../services/api-services.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;
  public contactForm: FormGroup;
  public isSubmitted: boolean;
  public isSubmittedComplete: boolean;
  public isButtonLoading: boolean;
  
  constructor(private fb: FormBuilder, public apiServices : ApiServicesService) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      school: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      message: [null, Validators.required],
    });
  }

  async submitQuery() {
    this.isSubmitted = true;

    if (!this.contactForm.invalid) {

      const subject = 'Website Enquiry';
      const recpient = 'support@lunchcard.co.za';

      const htmlBody = 'New Website Enquiry <br/>'
      + 'Name: ' + this.contactForm.get('name').value +  '<br/>'
      + 'School: ' + this.contactForm.get('school').value +  '<br/>'
      + 'Email: '+ this.contactForm.get('email').value +  '<br/>'
      + 'Phone: ' + this.contactForm.get('phone').value +  '<br/>'
      + 'Message: ' + this.contactForm.get('message').value +  '<br/>'

      const textBody = 'New Website Enquiry,'
      + 'Name: ' + this.contactForm.get('name').value + '/r'
      + 'School: ' + this.contactForm.get('school').value + '/r'
      + 'Email: '+ this.contactForm.get('email').value + '/r'
      + 'Phone: ' + this.contactForm.get('phone').value + '/r'
      + 'Message: ' + this.contactForm.get('message').value + '/r'

      this.isButtonLoading =  true;
      const submitQueryResult = await this.apiServices.sendEmail(
        recpient, subject, htmlBody, textBody,
        this.contactForm.get('name').value,
        this.contactForm.get('email').value,
      );
      this.isButtonLoading =  false;

      if (submitQueryResult) {
        this.isSubmittedComplete = true;
      }
    }
  }
}
