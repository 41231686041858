import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentDetails } from 'src/app/Models/StudentDetails';
import { ApiServicesService } from '../../../services/api-services.service';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.scss'],
})
export class ViewStudentComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;
  @Input() currentStudent: StudentDetails;
  @Output() quickTopup = new EventEmitter();
  @Output() suspendStudent = new EventEmitter();
  @Output() activateStudent = new EventEmitter();
  @Output() viewReports = new EventEmitter();
  @Output() orderReplacementCard = new EventEmitter();
  @Output() restrictItems = new EventEmitter();

  public editName = false;
  public editSurname = false;
  public editGrade = false;
  public editDailyLimit = false;
  public studentDetail: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiServices: ApiServicesService
  ) {}

  ngOnInit(): void {
    if (this.screenHeight < 750 || this.screenWidth < 700) {
      this.screenHeight = this.screenHeight * 1.3;
    }
    this.studentDetail = this.fb.group({
      name: [this.currentStudent.basicDetails.name, Validators.required],
      surname: [this.currentStudent.surname, Validators.required],
      grade: [this.currentStudent.grade, Validators.required],
      dailyLimit: [this.currentStudent.spendLimit, Validators.required],
    });
  }

  async saveStudent() {
    this.currentStudent.basicDetails.name = this.studentDetail.get(
      'name'
    ).value;
    this.currentStudent.surname = this.studentDetail.get('surname').value;
    this.currentStudent.grade = this.studentDetail.get('grade').value;
    this.currentStudent.spendLimit = parseFloat(
      this.studentDetail.get('dailyLimit').value
    );

    let studentDetails = {
      studentId: this.currentStudent.basicDetails.studentId,
      name: this.currentStudent.basicDetails.name,
      surname: this.currentStudent.surname,
      grade: this.currentStudent.grade,
      spendLimit: this.currentStudent.spendLimit,
    };

    const studentUpdateResult = await this.apiServices.updateStudent(
      studentDetails
    );
  }

  quickTopupClick() {
    this.quickTopup.emit(this.currentStudent.basicDetails);
  }

  suspendStudentClick() {
    this.suspendStudent.emit(this.currentStudent.basicDetails);
  }

  activateStudentClick() {
    this.activateStudent.emit(this.currentStudent.basicDetails);
  }

  viewStatsClick() {
    this.viewReports.emit(this.currentStudent.basicDetails);
  }

  orderReplacementCardClick() {
    this.orderReplacementCard.emit(this.currentStudent.basicDetails);
  }

  restrictItemsClick() {
    this.restrictItems.emit(this.currentStudent.basicDetails);
  }
}
