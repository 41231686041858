import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import * as moment from 'moment';

@Component({
  selector: 'app-meal-planning-date-selection',
  templateUrl: './meal-planning-date-selection.component.html',
  styleUrls: ['./meal-planning-date-selection.component.scss'],
})
export class MealPlanningDateSelectionComponent
  implements OnInit, AfterViewInit {
  @Input() screenHeight: number;
  @Input() orderDays: [];
  @Input() selectedStudent: BasicStudent;
  @Input() timeSlots;
  @Output() dateSelected = new EventEmitter();
  currentMonthDate = moment(new Date());

  dayRows = [];

  constructor(
    private apiServices: ApiServicesService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.generateDayRows();
    if (this.screenHeight < 750) {
      // Smaller Laptop Screens
      this.screenHeight = this.screenHeight * 1.2;
    }
  }

  ngAfterViewInit() {
    this.getOrderDays();
  }

  generateDayRows() {
    this.dayRows = [];
    let dayRow = [];
    for (var i = 0; i < this.orderDays.length; i++) {
      dayRow.push(this.orderDays[i]);
      if ((i + 1) % 5 == 0 && i > 0) {
        this.dayRows.push(dayRow);
        dayRow = [];
      }
    }
  }

  selectDay(selectedDay) {
    this.dateSelected.emit(selectedDay);
  }

  async setMonth(monthDirection) {
    this.currentMonthDate = this.currentMonthDate.add(monthDirection, 'months');
    this.getOrderDays();
  }

  async getOrderDays() {
    this.orderDays = await this.apiServices.getOrderDays(
      this.currentMonthDate.format('yyyy-MM-DD'),
      this.selectedStudent.studentId
    );
    this.generateDayRows();
  }
}
