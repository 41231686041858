import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationPopupData } from '../services/general.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NotificationPopupData,
    public dialogRef: MatDialogRef<NotificationPopupComponent>
  ) {}

  ngOnInit(): void {}
}
