import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { InvokeFunctionExpr } from '@angular/compiler';
import { ApiServicesService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-meal-planning-order-checkout',
  templateUrl: './meal-planning-order-checkout.component.html',
  styleUrls: ['./meal-planning-order-checkout.component.scss'],
})
export class MealPlanningOrderCheckoutComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() selectedStudent: BasicStudent;
  @Input() selectedItems;
  @Output() itemAdded = new EventEmitter();
  @Output() itemRemoved = new EventEmitter();
  @Output() handleBackClicked = new EventEmitter();
  @Output() handleFinishClicked = new EventEmitter();

  orderNotes = '';

  constructor(
    public generalService: GeneralService,
    public apiServices: ApiServicesService
  ) {}

  ngOnInit(): void {}

  addItem(item) {
    this.itemAdded.emit(item);
  }

  removeItem(item) {
    this.itemRemoved.emit(item);
  }

  backClicked() {
    this.handleBackClicked.emit('Category selection');
  }

  finishClicked() {
    this.handleFinishClicked.emit(this.orderNotes);
  }
}
