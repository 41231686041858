import { Component, OnInit, Input } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import * as moment from 'moment';
import { Label } from 'ng2-charts';
import { ThrowStmt } from '@angular/compiler';
import { ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  students = [];
  selectedStudent: BasicStudent;
  currentMonthDate;
  monthPurchasesData = [];
  categoryReportData = [];
  dailySpendReportData = [];
  pieChartLabels: Label[];
  pieChartData: number[];
  barChartLabels: Label[] = [];
  barChartData: ChartDataSets[] = [];
  @Input() screenHeight;
  @Input() isMobile: number;

  constructor(
    private apiServices: ApiServicesService,
    public generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.getStudents();
    this.currentMonthDate = moment(new Date());
  }

  async getStudents() {
    this.students = await this.apiServices.getStudents();
  }

  async getStudentReportData() {
    await this.getStudentMonthPurchases();
    await this.getCategoryReportData();
    await this.getDailySpendReportData();
  }

  async handleStudentClicked(student) {
    this.selectedStudent = student;
    await this.getStudentReportData();
    this.generalService.currentChildScreen = 'View data';
  }

  async setCurrentMonth(monthDirection) {
    this.currentMonthDate = this.currentMonthDate.add(monthDirection, 'months');
    this.getStudentReportData();
  }

  async getStudentMonthPurchases() {
    this.monthPurchasesData = await this.apiServices.getStudentMonthPurchases(
      this.selectedStudent.studentId,
      this.currentMonthDate.format('yyyy-MM-DD')
    );
  }

  async generateCategoryDataStructures() {
    let lables = [];
    let dataValues = [];
    for (var i = 0; i < this.categoryReportData.length; i++) {
      lables.push(this.categoryReportData[i].categoryName);
      dataValues.push(this.categoryReportData[i].amount);
    }
    this.pieChartLabels = lables;
    this.pieChartData = dataValues;
  }

  async generateDailySpendDataStructures() {
    let lables = [];
    let dataValues = [];
    for (var i = 0; i < this.dailySpendReportData.length; i++) {
      lables.push(this.dailySpendReportData[i].dayName);
      dataValues.push(this.dailySpendReportData[i].spendAmount);
    }
    this.barChartLabels = lables;
    this.barChartData = [
      {
        data: dataValues,
        label: 'Daily spend',
        backgroundColor: 'rgba(0, 148, 211, 0.3',
      },
    ];
  }

  async getCategoryReportData() {
    this.categoryReportData = await this.apiServices.getCategoryReport(
      this.selectedStudent.studentId,
      this.currentMonthDate.format('yyyy-MM-DD')
    );

    await this.generateCategoryDataStructures();
  }

  async getDailySpendReportData() {
    this.dailySpendReportData = await this.apiServices.getDailySpentReport(
      this.selectedStudent.studentId,
      this.currentMonthDate.format('yyyy-MM-DD')
    );

    await this.generateDailySpendDataStructures();
  }
}
