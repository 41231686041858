import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import * as moment from 'moment';

@Component({
  selector: 'app-meal-planning-order-summary',
  templateUrl: './meal-planning-order-summary.component.html',
  styleUrls: ['./meal-planning-order-summary.component.scss'],
})
export class MealPlanningOrderSummaryComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() selectedMealPlanId: number;
  @Input() selectedStudent: BasicStudent;
  @Input() selectedItems;
  @Input() selectedOrderDate;
  @Input() isExistingMealPlan;
  @Output() handleCloseClicked = new EventEmitter();
  @Output() handleRemoveMealPlanItem = new EventEmitter();
  @Output() handleCancelOrderClicked = new EventEmitter();

  orderDate;

  constructor(public generalService: GeneralService) {}

  ngOnInit(): void {
    this.orderDate = moment(this.selectedOrderDate).format('yyyy-MM-DD');
  }

  closeClicked() {
    this.handleCloseClicked.emit();
  }

  cancelOrder() {
    this.handleCancelOrderClicked.emit(this.selectedMealPlanId);
  }

  removeMealPlanItem(orderItem, listItemIndex) {
    this.selectedItems.splice(listItemIndex, 1);
    this.handleRemoveMealPlanItem.emit(orderItem);
  }
}
