<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight"
>
  <div class="portal-heading">Quick topup</div>
  <div class="portal-subheading">Top up {{ student.name }}'s account<br /></div>
  <div class="students-container">
    <div>
      <div class="student-picture-parent" *ngIf="!showDoneAnimation">
        <img src="{{ student.picture }}" class="student-picture" />
        <div class="balance-box">
          <div class="balance-box-child">Balance: R{{ student.balance }}</div>
        </div>
      </div>
      <div class="student-picture-parent" *ngIf="showDoneAnimation">
        <lottie-player
          slot="end"
          autoplay
          src="https://assets5.lottiefiles.com/datafiles/kbuj8xpg2U73q9B/data.json"
        ></lottie-player>
      </div>
      <div class="buttons-box">
        <div class="small-text-field-box">
          <input
            class="small-text-field"
            [(ngModel)]="topupAmount"
            placeholder="Amount"
            type="number"
          />
        </div>
        <div *ngIf="errorText" class="error-text">
          {{ errorText }}
        </div>
        <div (click)="finishTopup()" class="portal-alternate-button">
          <div class="button-loader" *ngIf="isButtonLoading"></div>
          <div *ngIf="!isButtonLoading">Topup</div>
        </div>
        <div class="portal-button" (click)="cancelTopup()">Cancel</div>
      </div>
    </div>
  </div>
  <div class="parent-balance-box">
    <div class="profile-circle">
      {{ generalService.parentDetails?.name | titlecase | slice: 0:1 }}
      {{ generalService.parentDetails?.surname | titlecase | slice: 0:1 }}
    </div>
    <div class="balance-text">
      Your available funds: R{{
        generalService.parentDetails ? generalService.parentDetails.balance : 0
      }}
    </div>
  </div>
</div>
