<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading">Topup</div>
  <div class="portal-subheading">
    Topup your students' accounts here<br />
    Enter the amount you would like to topup with below
  </div>
  <div class="students-container">
    <div *ngFor="let student of students">
      <div class="student-picture-parent" *ngIf="!student.showDoneAnimation">
        <img
          src="{{ student.picture }}"
          class="student-picture"
          draggable="false"
        />
        <div class="balance-box">
          <div class="balance-box-child">Balance: R{{ student.balance }}</div>
        </div>
      </div>
      <div class="student-picture-parent" *ngIf="student.showDoneAnimation">
        <lottie-player
          slot="end"
          autoplay
          src="https://assets5.lottiefiles.com/datafiles/kbuj8xpg2U73q9B/data.json"
        ></lottie-player>
      </div>
      <div class="student-name-text">{{ student.name }}</div>
      <div class="buttons-box">
        <div class="small-text-field-box">
          <input
            class="small-text-field"
            [(ngModel)]="student.topupAmount"
            placeholder="Amount"
            type="number"
          />
        </div>
        <div *ngIf="student.errorText" class="error-text">
          {{ student.errorText }}
        </div>
        <div (click)="finishTopup(student)" class="portal-alternate-button">
          Topup
        </div>
      </div>
    </div>
  </div>
  <div class="parent-balance-box">
    <div class="profile-circle">
      {{ generalService.parentDetails?.name | titlecase | slice: 0:1 }}
      {{ generalService.parentDetails?.surname | titlecase | slice: 0:1 }}
    </div>
    <div class="balance-text">
      Your available funds: R{{
        generalService.parentDetails ? generalService.parentDetails.balance : 0
      }}
    </div>
  </div>
</div>
