<div class="menu-bar">
  <div class="logo-box" (click)="handleRouteClicked('home')">
    <img src="../../assets/logo.png" width="150" />
  </div>
  <div class="links-box">
    <div class="link-button" (click)="handleRouteClicked('home')">Home</div>
    <div class="link-button" (click)="handleRouteClicked('about')">About</div>
    <div class="link-button" (click)="handleRouteClicked('contact')">
      Contact
    </div>
    <div
      class="link-button link-primary-button"
      (click)="handleRouteClicked('login')"
    >
      Login
    </div>
    <div
      class="link-button link-secondary-button"
      *ngIf="!(currentUrl === '/register')"
      (click)="handleRouteClicked('register')"
    >
      Register
    </div>
  </div>
</div>
