import { Injectable } from '@angular/core';
import { BasicStudent } from '../Models/BasicStudent';
import { ParentDetails } from '../Models/ParentDetails';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

export interface NotificationPopupData {
  title: string;
  message: string;
}

export interface ConfirmationPopupData {
  title: string;
  message: string;
  yesButtonText: string;
}

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  currentStudent: BasicStudent;
  currentScreen: string = 'Students';
  currentChildScreen: string = 'Student selection';
  parentDetails: ParentDetails;
  allParentsDetails : ParentDetails[];
  isLoading: boolean;

  constructor(public dialog: MatDialog) {}

  showNotification(title, message) {
    this.dialog.open(NotificationPopupComponent, {
      data: {
        title: title,
        message: message,
      },
    });
  }

  confirmDialog(title, message, yesButtonText) {
    return this.dialog
      .open(ConfirmationPopupComponent, {
        data: {
          title: title,
          message: message,
          yesButtonText: yesButtonText,
        },
      })
      .afterClosed();
  }
}
