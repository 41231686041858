import { Component, OnInit, HostListener } from '@angular/core';
import { GeneralService } from '../services/general.service';
import { BasicStudent } from '../Models/BasicStudent';
import { ApiServicesService } from '../services/api-services.service';
import { ParentDetails } from '../Models/ParentDetails';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent {
  screenHeight: number;
  screenWidth: number;
  isMobile: boolean = false;
  parent: ParentDetails;
  menuUrl;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 700) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  constructor(
    public apiServices: ApiServicesService,
    public generalService: GeneralService
  ) {
    this.getScreenSize();
    this.apiServices.getParentDetails();
    this.apiServices.getAllParentsDetails();
    this.parent = this.generalService.parentDetails;
  }

  ngOnInit(): void {
    this.getSchoolDetails();
  }

  async getSchoolDetails() {
    this.apiServices.schoolDetails = await this.apiServices.getSchoolDetails();
    this.menuUrl = this.apiServices.schoolDetails.menuUrl;
  }
}
