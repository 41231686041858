import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-header-bar',
  templateUrl: './mobile-header-bar.component.html',
  styleUrls: ['./mobile-header-bar.component.scss'],
})
export class MobileHeaderBarComponent implements OnInit {
  constructor(private router: Router) {}
  @Output() openMenu = new EventEmitter();

  ngOnInit(): void {}

  handleOpenMenuClicked() {
    this.openMenu.emit();
  }

  handleLoginClicked() {
    this.router.navigate(['/', 'login']);
  }
}
