import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';
import { InactiveModalComponent } from '../../inactive-modal/inactive-modal.component';

@Component({
  selector: 'app-meal-planning-student-selection',
  templateUrl: './meal-planning-student-selection.component.html',
  styleUrls: ['./meal-planning-student-selection.component.scss'],
})
export class MealPlanningStudentSelectionComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() isMobile: number;
  @Input() students;
  @Output() studentSelected = new EventEmitter();

  constructor(
    private apiServices: ApiServicesService,
    private generalService: GeneralService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {}

  selectStudent(student) {
    if (student.status === 'Inactive') {
      this.openInactiveModal();
      return false;
    }

    this.studentSelected.emit(student);
  }

  openInactiveModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '500px';
    dialogConfig.width = '700px';
    const modalDialog = this.matDialog.open(
      InactiveModalComponent,
      dialogConfig
    );
  }
}
