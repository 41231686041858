import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss'],
})
export class AddStudentComponent implements OnInit {
  constructor(
    public generalService: GeneralService,
    private apiService: ApiServicesService,
    private fb: FormBuilder
  ) {}

  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;
  @Output() getStudents = new EventEmitter();

  showDoneAnimation: boolean = false;
  public addStudent: FormGroup;
  public isCurrentRequest = false;
  public isButtonLoading: boolean = false;

  ngOnInit(): void {
    this.addStudent = this.fb.group({
      keyCard: ['', Validators.required],
      pin: ['', Validators.required],
    });
  }

  async saveAddStudent() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    this.isButtonLoading = true;
    const result = await this.apiService.addStudent(
      this.addStudent.get('keyCard').value,
      this.addStudent.get('pin').value
    );
    this.isButtonLoading = false;

    if (result) {
      this.showDoneAnimation = true;
      this.getStudents.emit();
      setTimeout(() => {
        this.showDoneAnimation = false;
        this.generalService.currentScreen = 'Students';
        this.generalService.currentChildScreen = 'Student selection';
      }, 2000);
    } else {
      alert(
        'Unable to add student, please check card number <br/>and ensure all other student are active'
      );
    }
    this.isCurrentRequest = false;
  }

  cancelAddStudent() {
    this.generalService.currentScreen = 'Students';
    this.generalService.currentChildScreen = 'Student selection';
  }
}
