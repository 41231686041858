<app-portal-menu-bar></app-portal-menu-bar>
<div class="portal-container" [style.height.px]="screenHeight - 230">
  <div class="portal-heading">Payment successful</div>
  <div class="auto-topup-text">
    Thank you, your payment has been successfully processed.
  </div>
  <div class="validated-text">
    Students linked to your profile will be automatically activated.
  </div>
  <div class="button-container">
    <div class="portal-button" (click)="doneClicked()">Done</div>
  </div>
</div>
