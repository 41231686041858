import { Component, OnInit, Input } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-bank-deposit',
  templateUrl: './bank-deposit.component.html',
  styleUrls: ['./bank-deposit.component.scss'],
})
export class BankDepositComponent implements OnInit {
  @Input() screenHeight: number;
  public bankDetails;

  constructor(public generalService: GeneralService, private apiServices: ApiServicesService) {}

  ngOnInit(): void {
    this.getBankDetails();
  }

  async getBankDetails() {
    this.bankDetails = await this.apiServices.getSchoolBankingDetails();
  }

  changeScreen(childScreen) {
    this.generalService.currentChildScreen = childScreen;
  }
}
