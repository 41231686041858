import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;

  constructor() {}

  ngOnInit(): void {}
}
