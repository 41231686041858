<app-portal-menu-bar></app-portal-menu-bar>
<div
  class="portal-container"
  [style.height.px]="isMobile ? 'unset' : screenHeight - 230"
>
  <div class="portal-heading">{{ headingText }}</div>
  <div class="loader" *ngIf="isLoading"></div>
  <div class="validated-circle" *ngIf="paymentNotValidated || paymentValidated">
    <mat-icon
      *ngIf="paymentNotValidated"
      class="validated-circle-text"
      aria-hidden="false"
      aria-label="Example home icon"
      >clear</mat-icon
    >
    <lottie-player
      *ngIf="paymentValidated"
      slot="end"
      autoplay
      src="https://assets5.lottiefiles.com/datafiles/kbuj8xpg2U73q9B/data.json"
    ></lottie-player>
  </div>
  <div class="auto-topup-text">{{ autoTopupText }}</div>
  <div class="validated-text">{{ validatedText }}</div>
  <div class="button-container">
    <div class="portal-button" (click)="doneClicked()">
      {{ buttonText }}
    </div>
  </div>
</div>
