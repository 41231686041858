<div class="portal-container" [style.minHeight.px]="screenHeight - 220">
  <div class="portal-heading">Meal planning</div>
  <div class="portal-subheading">View past order & create new ones<br /></div>
  <div class="create-button-container" (click)="createNewOrder()">
    <div class="icon-circle">
      <svg
        class="icon-box"
        style="width: 80px; height: 80px"
        viewBox="0 0 24 24"
      >
        <path
          fill="grey"
          d="M12.5,1.5C10.73,1.5 9.17,2.67 8.67,4.37C8.14,4.13 7.58,4 7,4A4,4 0 0,0 3,8C3,9.82 4.24,11.41 6,11.87V19H19V11.87C20.76,11.41 22,9.82 22,8A4,4 0 0,0 18,4C17.42,4 16.86,4.13 16.33,4.37C15.83,2.67 14.27,1.5 12.5,1.5M12,10.5H13V17.5H12V10.5M9,12.5H10V17.5H9V12.5M15,12.5H16V17.5H15V12.5M6,20V21A1,1 0 0,0 7,22H18A1,1 0 0,0 19,21V20H6Z"
        />
      </svg>
    </div>
    <div class="button-box">View / Create Order</div>
  </div>
  <div class="portal-subheading" *ngIf="!isMealPlanningAllowed">
    <br />Meal planning is not allowed for this school<br />
  </div>
  <div class="table-container" *ngIf="isMealPlanningAllowed">
    <div class="table-header-container" *ngIf="recentMealPlans.length != 0">
      <div class="table-header-text">Recent meal plans</div>
    </div>
    <div class="row-data" *ngFor="let rowItem of recentMealPlans">
      <div class="date-column">
        <div class="row-text">{{ rowItem.orderDate }}</div>
      </div>
      <div class="description-column">
        <div class="row-text">{{ rowItem.itemsDescription }}</div>
      </div>
      <div class="amount-column">
        <div class="row-text">R{{ rowItem.total }}</div>
      </div>
      <div class="amount-column">
        <div class="row-text">{{ rowItem.orderStatus }}</div>
      </div>
      <div class="button-column">
        <div class="action-column" (click)="viewMealPlanItem(rowItem)">
          <mat-icon
            class="vis-icon"
            aria-hidden="false"
            aria-label="Example icon"
            >visibility
          </mat-icon>
          <div class="action-column-text">View details</div>
        </div>
      </div>
    </div>
  </div>
</div>
