import { Component, OnInit, Input } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { TopupDetails } from 'src/app/Models/TopupDetails';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InactiveModalComponent } from '../inactive-modal/inactive-modal.component';

@Component({
  selector: 'app-quick-topup',
  templateUrl: './quick-topup.component.html',
  styleUrls: ['./quick-topup.component.scss'],
})
export class QuickTopupComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() isMobile: number;

  student: BasicStudent;
  topupAmount: string;
  errorText: string = '';
  showDoneAnimation: boolean = false;
  public isCurrentRequest = false;
  public isButtonLoading: boolean = false;

  constructor(
    public generalService: GeneralService,
    private apiService: ApiServicesService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.student = this.generalService.currentStudent;
  }

  validateAmount() {
    this.errorText = '';
    const topupNumberAmount: number = this.topupAmount
      ? parseInt(this.topupAmount)
      : 0;
    if (topupNumberAmount <= 0) {
      this.errorText = 'Please enter a valid amount';
      return false;
    }

    if (this.generalService.currentStudent.status === 'Inactive') {
      this.openInactiveModal();
      return false;
    }

    if (topupNumberAmount > this.generalService.parentDetails.balance) {
      this.generalService.showNotification(
        'Insufficient funds',
        'You do not have enough funds in your parent account to do this topup.<br/>Remember, you can do an instant topup from the "Deposit funds" page.'
      );
      return false;
    }

    return true;
  }

  async finishTopup() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    if (this.validateAmount()) {
      const topupDetails: TopupDetails = {
        studentIds: [this.generalService.currentStudent.studentId],
        amount: parseInt(this.topupAmount),
      };

      this.isButtonLoading = true;
      const result = await this.apiService.performTopup(topupDetails);
      this.isButtonLoading = false;

      if (result) {
        this.showDoneAnimation = true;
        setTimeout(() => {
          this.showDoneAnimation = false;
          this.generalService.currentScreen = 'Students';
        }, 2000);
      }
    }
    this.isCurrentRequest = false;
  }

  openInactiveModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '500px';
    dialogConfig.width = '700px';
    const modalDialog = this.matDialog.open(
      InactiveModalComponent,
      dialogConfig
    );
  }

  cancelTopup() {
    this.generalService.currentScreen = 'Students';
  }
}
