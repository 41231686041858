import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-replacement-card-order-complete',
  templateUrl: './replacement-card-order-complete.component.html',
  styleUrls: ['./replacement-card-order-complete.component.scss'],
})
export class ReplacementCardOrderCompleteComponent implements OnInit {
  constructor(private router: Router) {}

  screenHeight: number;
  screenWidth: number;

  ngOnInit(): void {}

  doneClicked() {
    this.router.navigate(['/', 'portal']);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
}
