import { Component, OnInit, Input } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-select-deposit-option',
  templateUrl: './select-deposit-option.component.html',
  styleUrls: ['./select-deposit-option.component.scss'],
})
export class SelectDepositOptionComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isInstantPaymentAllowed: boolean;
  constructor(public generalService: GeneralService) {}

  ngOnInit(): void {
  }

  changeScreen(childScreen) {
    this.generalService.currentChildScreen = childScreen;
  }
}
