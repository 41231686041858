<div class="portal-container" [style.minHeight.px]="screenHeight - 220">
  <div class="portal-heading" *ngIf="!isExistingMealPlan">
    Meal plan summary
  </div>
  <div class="portal-heading" *ngIf="isExistingMealPlan">Meal plan</div>

  <div class="portal-subheading" *ngIf="!isExistingMealPlan">
    These are the details for your order
    <br />
  </div>
  <div class="portal-subheading" *ngIf="isExistingMealPlan">
    These are the details of your saved meal plan
    <br />
  </div>

  <div class="order-complete-icon" *ngIf="!isExistingMealPlan">
    <lottie-player
      slot="end"
      autoplay
      src="https://assets5.lottiefiles.com/datafiles/kbuj8xpg2U73q9B/data.json"
    ></lottie-player>
  </div>
  <div class="portal-heading" *ngIf="!isExistingMealPlan">ORDER COMPLETE</div>

  <div class="table-container">
    <div class="table-header-container">
      <div class="table-header-text" *ngIf="!isExistingMealPlan">
        Order summary for {{ orderDate }}
      </div>
      <div class="table-header-text" *ngIf="isExistingMealPlan">
        Meal plan for for {{ orderDate }}
      </div>
    </div>
    <div
      class="row-data"
      *ngFor="let orderItem of selectedItems; let listItemIndex = index"
    >
      <div class="date-column">
        <div class="row-text">{{ orderDate }}</div>
      </div>
      <div class="description-column">
        <div class="row-text">
          {{ orderItem.qty }}x {{ orderItem.description }}
        </div>
      </div>
      <div class="amount-column">
        <div class="row-text">R{{ orderItem.qty * orderItem.price }}</div>
      </div>
      <div class="button-column" *ngIf="false">
        <mat-icon
          (click)="removeMealPlanItem(orderItem, listItemIndex)"
          class="delete-icon"
          aria-hidden="false"
          aria-label="Example icon"
          *ngIf="orderItem.status === 'Ordered'"
          >delete
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="bottom-buttons-box">
    <div
      class="portal-alternate-button"
      (click)="cancelOrder()"
      *ngIf="isExistingMealPlan"
    >
      Cancel order
    </div>
    <div class="portal-button" (click)="closeClicked()">Close</div>
  </div>
</div>
