import { Component, OnInit, Input } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { TopupDetails } from 'src/app/Models/TopupDetails';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InactiveModalComponent } from '../inactive-modal/inactive-modal.component';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss'],
})
export class TopupComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;

  students = [];
  showDoneAnimation: Boolean = false;
  public isCurrentRequest = false;

  constructor(
    private apiService: ApiServicesService,
    public generalService: GeneralService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getStudents();
    if (this.screenHeight < 750 || this.screenWidth < 700) {
      this.screenHeight = this.screenHeight * 1.3;
    }
  }

  async getStudents() {
    this.students = await this.apiService.getStudents();
    this.students.map((student) => {
      student.topupAmount = '';
      student.errorText = '';
      student.showDoneAnimation = false;
    });
  }

  validateAmount(student) {
    student.errorText = '';
    const topupNumberAmount: number = student.topupAmount
      ? parseInt(student.topupAmount)
      : 0;
    if (topupNumberAmount <= 0) {
      student.errorText = 'Please enter a valid amount';
      return false;
    }

    if (topupNumberAmount > this.generalService.parentDetails.balance) {
      this.generalService.showNotification(
        'Insufficient funds',
        'You do not have enough funds in your parent account to do this topup.<br/>Remember, you can do an instant topup from the "Deposit funds" page.'
      );
      return false;
    }

    return true;
  }

  openInactiveModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '500px';
    dialogConfig.width = '700px';
    const modalDialog = this.matDialog.open(
      InactiveModalComponent,
      dialogConfig
    );
  }

  async finishTopup(student) {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    if (student.status === 'Inactive') {
      this.openInactiveModal();
      return false;
    }

    if (this.validateAmount(student)) {
      const topupDetails: TopupDetails = {
        studentIds: [student.studentId],
        amount: parseInt(student.topupAmount),
      };

      const result = await this.apiService.performTopup(topupDetails);
      if (result) {
        this.apiService.getParentDetails();
        student.showDoneAnimation = true;
        setTimeout(() => {
          student.showDoneAnimation = false;
          this.getStudents();
        }, 2000);
      }
    }
    this.isCurrentRequest = false;
  }
}
