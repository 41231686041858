<div class="portal-heading">Instant deposit</div>
<div class="portal-subheading">
  Secure payment, allocated immediately
</div>
<div class="deposit-icon-box">
  <div class="deposit-option">
    <div class="deposit-option-icon">
      <mat-icon
        class="deposit-option-icon-text"
        aria-hidden="false"
        aria-label="Example home icon"
        >payment</mat-icon
      >
    </div>
  </div>
</div>
<div class="amount-container">
  <div class="instruction-text">
    Enter an amount (R1 - R200)
  </div>
  <div class="small-text-field-box">
    <input
      class="small-text-field"
      [(ngModel)]="depositAmount"
      placeholder="Amount"
      type="number"
      min="1"
    />
  </div>
  <div class="error-text" *ngIf="errorText">{{ errorText }}</div>
  <mat-slide-toggle
    [(ngModel)]="autoTopup"
    color="#0095d3"
    class="instruction-text"
    >Automatically topup students' accounts</mat-slide-toggle
  >
  <div class="buttons-box">
    <div class="portal-alternate-button" (click)="nextClicked()">
      Next
    </div>
    <div class="portal-button" (click)="cancelClicked()">
      Cancel
    </div>
  </div>
</div>
<div class="payment-container">
  <form
    #paymentForm
    novalidate
    role="form"
    action="https://secure.paygate.co.za/payweb3/process.trans"
    method="POST"
    [formGroup]="cardRequestForm"
  >
    <input
      type="hidden"
      name="PAY_REQUEST_ID"
      formControlName="PAY_REQUEST_ID"
    />
    <input type="hidden" name="CHECKSUM" formControlName="CHECKSUM" />
  </form>
</div>
