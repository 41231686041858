import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-meal-planning-index',
  templateUrl: './meal-planning-index.component.html',
  styleUrls: ['./meal-planning-index.component.scss'],
})
export class MealPlanningIndexComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() recentMealPlans;
  @Input() isMealPlanningAllowed;
  @Input() students;
  @Output() handleCreateNewOrderClicked = new EventEmitter();
  @Output() dateSelected = new EventEmitter();
  @Output() mealPlanSelected = new EventEmitter();

  constructor(private generalService: GeneralService) {}

  ngOnInit(): void {}

  createNewOrder() {
    if (this.isMealPlanningAllowed) {
      this.handleCreateNewOrderClicked.emit();
    }
  }

  viewMealPlanItem(orderItem) {
    this.mealPlanSelected.emit(orderItem);
  }

  cancel() {
    this.generalService.currentScreen = 'Students';
  }
}
