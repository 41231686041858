<div class="portal-container" [style.height.px]="screenHeight - 220">
  <div class="portal-heading">Select order time slot</div>
  <div class="portal-subheading">
    Select the time for
    {{ selectedStudent ? selectedStudent.name : "" }} order<br />
  </div>
  <div class="time-slot-boxes">
    <div
      class="time-slot"
      *ngFor="let timeSlot of timeSlots"
      (click)="timeSlotClicked(timeSlot)"
    >
      {{ timeSlot.timeSlot }}
    </div>
  </div>
</div>
