<app-menubar
  *ngIf="!isMobile"
  (emitNavClick)="emitNavClick($event)"
></app-menubar>
<app-home
  *ngIf="
    !isMobile &&
    (location == '/' ||
      location == '/home' ||
      location == '/about' ||
      location == '/contact')
  "
  [isMobile]="isMobile"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
  [scrollTo]="scrollTo"
></app-home>
<app-privacy
  *ngIf="location == '/privacy' && !isMobile"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
></app-privacy>
<app-termsandconditions
  *ngIf="location == '/terms-and-conditions' && !isMobile"
  [screenHeight]="screenHeight"
  [screenWidth]="screenWidth"
></app-termsandconditions>
<app-registration
  *ngIf="location == '/register' && !isMobile"
></app-registration>

<mat-sidenav-container class="sidenav-container" *ngIf="isMobile">
  <mat-sidenav
    #sidenav
    autoFocus="false"
    [(opened)]="showMenu"
    mode="over"
    class="sidenav sidenav-fixed"
  >
    <div class="header">Menu</div>
    <div class="menu-buttons">
      <a mat-button (click)="handleRouteClicked('home')" class="menu-button">
        <div class="menu-button">
          <mat-icon class="menu-button-icon">home</mat-icon>
          <div class="menu-button-text">Home</div>
        </div>
      </a>
      <a mat-button (click)="handleRouteClicked('about')" class="menu-button">
        <div class="menu-button">
          <mat-icon class="menu-button-icon">info</mat-icon>
          <div class="menu-button-text">About</div>
        </div>
      </a>
      <a mat-button (click)="handleRouteClicked('contact')" class="menu-button">
        <div class="menu-button">
          <mat-icon class="menu-button-icon">email</mat-icon>
          <div class="menu-button-text">Contact</div>
        </div>
      </a>
      <a mat-button (click)="handleRouteClicked('login')" class="menu-button">
        <div class="menu-button">
          <mat-icon class="menu-button-icon">lock</mat-icon>
          <div class="menu-button-text">Login</div>
        </div>
      </a>
      <a
        mat-button
        (click)="handleRouteClicked('register')"
        class="menu-button"
      >
        <div class="menu-button">
          <mat-icon class="menu-button-icon">account_circle</mat-icon>
          <div class="menu-button-text">Register</div>
        </div>
      </a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <router-outlet>
        <app-mobile-header-bar
          *ngIf="isMobile"
          (openMenu)="openMenu()"
        ></app-mobile-header-bar>
        <app-home
          *ngIf="
            isMobile &&
            (location == '/' ||
              location == '/home' ||
              location == '/about' ||
              location == '/contact')
          "
          [isMobile]="isMobile"
          [screenHeight]="screenHeight"
          [screenWidth]="screenWidth"
        ></app-home>

        <app-privacy
          *ngIf="location == '/privacy' && isMobile"
          [screenHeight]="screenHeight"
          [screenWidth]="screenWidth"
        ></app-privacy>
        <app-termsandconditions
          *ngIf="location == '/terms-and-conditions' && isMobile"
          [screenHeight]="screenHeight"
          [screenWidth]="screenWidth"
        ></app-termsandconditions>
        <app-registration
          [isMobile]="isMobile"
          *ngIf="location == '/register' && isMobile"
        ></app-registration>
      </router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
