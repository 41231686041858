import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasicStudent } from 'src/app/Models/BasicStudent';
import { StudentDetails } from 'src/app/Models/StudentDetails';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-set-restrictions',
  templateUrl: './set-restrictions.component.html',
  styleUrls: ['./set-restrictions.component.scss'],
})
export class SetRestrictionsComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;
  @Input() currentStudent: StudentDetails;
  @Input() selectedStudent: BasicStudent;
  @Input() categories;
  @Output() categorySelected = new EventEmitter();
  @Output() handleCartClicked = new EventEmitter();
  monthDays = [];
  public restrictedCategories;

  constructor(
    private apiServices: ApiServicesService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.getCategories();
  }

  async getCategories() {
    this.categories = await this.apiServices.getAllCategories();
    this.restrictedCategories = await this.apiServices.getRestrictedCategories(
      this.currentStudent.basicDetails.studentId
    );

    this.categories.forEach((category, i) => {
      this.restrictedCategories.forEach((restrictedCategory, j) => {
        if (restrictedCategory.categoryId === category.id)
          this.categories[i].restricted = true;
      });
    });
  }

  async selectCategory(category) {
    let restrictedCategories = [];
    this.categories.forEach((cat, index) => {
      if (category.id == cat.id) {
        this.categories[index].restricted = !this.categories[index].restricted;
      }
      if (cat.restricted) {
        restrictedCategories.push(cat.id);
      }
    });

    const restrictionsUpdateResult = await this.apiServices.updateRestrictions({
      studentId: this.currentStudent.basicDetails.studentId,
      categoryIds: restrictedCategories,
    });
  }

  cancel() {
    this.generalService.currentScreen = 'Students';
    this.generalService.currentChildScreen = 'View student';
  }
}
