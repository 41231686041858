import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { BasicStudent } from 'src/app/Models/BasicStudent';

@Component({
  selector: 'app-reports-view-data',
  templateUrl: './reports-view-data.component.html',
  styleUrls: ['./reports-view-data.component.scss'],
})
export class ReportsViewDataComponent implements OnInit {
  @Input() monthPurchasesData;
  @Input() screenHeight;
  @Input() isMobile: number;
  @Input() currentMonthDate;
  @Input() selectedStudent: BasicStudent;
  @Input() students: BasicStudent[];
  @Input() pieChartLabels: Label[];
  @Input() pieChartData: number[];
  @Input() barChartLabels: Label[];
  @Input() barChartData: ChartDataSets[];
  @Output() setCurrentMonth = new EventEmitter();
  @Output() handleStudentClicked = new EventEmitter();

  constructor() {}

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };

  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: [
        'rgba(244, 67, 54, 0.3)',
        'rgba(155, 39, 176, 0.3)',
        'rgba(33, 148, 243, 0.3)',
        'rgba(0, 150, 135, 0.3)',
        'rgba(76, 175, 79, 0.3)',
        'rgba(255, 235, 59, 0.3)',
        'rgba(96, 125, 139, 0.3)',
        'rgba(255, 153, 0, 0.3)',
        'rgba(204, 220, 57, 0.3)',
        'rgba(189, 189, 189, 0.3)',
        'rgba(83, 109, 254, 0.3)',
      ],
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  ngOnInit(): void {}

  handleChangeMonth(monthChange) {
    this.setCurrentMonth.emit(monthChange);
  }

  selectStudent(student) {
    this.handleStudentClicked.emit(student);
  }
}
