<div class="portal-heading">Deposit funds</div>
<div class="portal-subheading">
  Make a deposit into your parent account.<br />Easy and secure
</div>
<div class="deposit-options-parent">
  <div class="deposit-options-box" [ngStyle]="{'justify-content': isInstantPaymentAllowed ? 'space-between' : 'center'}">
    <div class="deposit-option" (click)="changeScreen('Instant deposit')" *ngIf="isInstantPaymentAllowed">
      <div class="deposit-option-icon">
        <mat-icon
          class="deposit-option-icon-text"
          aria-hidden="false"
          aria-label="Example home icon"
          >payment</mat-icon
        >
      </div>
      <div class="deposit-option-text">
        <strong>Instant deposit</strong><br />(R10 fee)
      </div>
    </div>
    <div class="deposit-option" (click)="changeScreen('Bank deposit')">
      <div class="deposit-option-icon">
        <mat-icon
          class="deposit-option-icon-text"
          aria-hidden="false"
          aria-label="Example home icon"
          >account_balance</mat-icon
        >
      </div>
      <div class="deposit-option-text">
        <strong>Bank deposit</strong><br />2 business days to clear<br />(No
        fee)
      </div>
    </div>
  </div>
</div>
