<div
  class="content-box"
  [style.height.px]="isMobile ? 'unset' : screenHeight * 0.65"
>
  <div class="content">
    <div
      [ngClass]="isMobile ? 'ellipse-mobile' : 'ellipse'"
      [style.height.px]="screenHeight"
    ></div>
    <div class="image-box">
      <img
        src="../../../assets/Lunch-Card-PC-Lap-Phone-Advert.png"
        [ngClass]="{ mobileImg: isMobile }"
        alt="laptop-tablet-phone-image"
        [width]="screenWidth * 0.6"
      />
    </div>
  </div>
</div>

<div class="about-container">
  <div [ngClass]="isMobile ? 'about-box-mobile' : 'about-box'">
    <div class="heading-text">Cashless schooling</div>
    <div class="heading-sub-text">
      lunchcard is a fully comprehensive cashless management system for use in
      schools and colleges across Southern Africa.<br /><br />Students in
      participating schools are given a photo-id lunchcard which they can use
      for purchases at most of the facilities their school has to offer. Once a
      student receives their lunchcard, the parent registers the card on the
      lunchcard website, upon completion of registration, the parent will have
      the ablility to perform various tasks such as: <br />
      <br />
      <div class="bullet-points-box">
        <ul class="content-list">
          <li>Deposit funds</li>
          <li>View transaction history</li>
          <li>Set daily spend limits</li>
          <li>Order school items</li>
        </ul>
        <ul class="content-list">
          <li>Set dietary restrictions</li>
          <li>View reports on purchases and other items</li>
          <li>Pre-order lunches from the tuckshop</li>
        </ul>
      </div>
    </div>
  </div>
</div>
