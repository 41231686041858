<div
  class="portal-container"
  [style.minHeight.px]="isMobile ? 'unset' : screenHeight - 220"
>
  <div class="portal-heading">Reports</div>
  <div class="portal-subheading">
    Select the student you would like to view reports for<br />
  </div>
  <div class="date-selection-box">
    <div class="student-selection-box">
      <div
        class="student-circle small-student-clickable"
        [style.borderWidth.px]="
          student.studentId == selectedStudent.studentId ? 3 : 0
        "
        [style.marginLeft.px]="i > 0 ? 20 : 0"
        *ngFor="let student of students; let i = index"
        (click)="selectStudent(student)"
      >
        <img
          class="student-circle-picture"
          [src]="student.picture"
          width="80"
          draggable="false"
        />
      </div>
    </div>
    <div class="header-bar">
      <div class="button-circle" (click)="handleChangeMonth(-1)">
        <mat-icon
          class="button-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >arrow_back</mat-icon
        >
      </div>
      <div class="header-bar-text">
        {{ currentMonthDate.format("MMMM yyyy") }}
      </div>
      <div class="button-circle" (click)="handleChangeMonth(1)">
        <mat-icon
          class="button-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >arrow_forward</mat-icon
        >
      </div>
    </div>
  </div>
  <div class="no-data-text" *ngIf="monthPurchasesData.length === 0">
    No data for this period
  </div>
  <div *ngIf="monthPurchasesData.length > 0">
    <div class="charts-container">
      <div class="category-chart">
        <canvas
          baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [colors]="pieChartColors"
          [legend]="pieChartLegend"
        >
        </canvas>
      </div>
      <div class="daily-spend-chart">
        <canvas
          baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
        >
        </canvas>
      </div>
    </div>
  </div>
  <div *ngIf="monthPurchasesData.length > 0">
    <div class="table-container">
      <div class="table-header-container">
        <div class="table-header-text">Purchases</div>
      </div>
      <div class="row-data" *ngFor="let rowItem of monthPurchasesData">
        <div class="date-column">
          <div class="row-text">{{ rowItem.purchaseDate }}</div>
        </div>
        <div class="description-column">
          <div class="row-text">{{ rowItem.description }}</div>
        </div>
        <div class="amount-column">
          <div class="row-text">R{{ rowItem.amount }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
