import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiServicesService } from 'src/app/services/api-services.service';
import * as moment from 'moment';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-inactive-modal',
  templateUrl: './inactive-modal.component.html',
  styleUrls: ['./inactive-modal.component.scss'],
})
export class InactiveModalComponent implements OnInit {
  constructor(
    public matDialog: MatDialog,
    public apiServices: ApiServicesService,
    public generalService: GeneralService
  ) {}

  schoolDetails;
  discount: number;

  ngOnInit(): void {
    this.getSchoolDetails();
  }

  changeScreen(screen) {}

  async getSchoolDetails() {
    this.schoolDetails = await this.apiServices.getSchoolDetails();
    this.discount = Math.round(
      ((this.schoolDetails.monthlyFee * 12 - this.schoolDetails.annualFee) /
        (this.schoolDetails.monthlyFee * 12)) *
        100
    );
  }

  cancelClicked() {
    this.matDialog.closeAll();
  }

  monthlyPayment() {
    let sPayVars =
      'https://www.payfast.co.za/eng/process?' +
      'merchant_id=10422300&' +
      'merchant_key=ol5gcdtri6odu&' +
      'return_url=https://lunchcard.co.za/service-fee-payment-successful&' +
      'cancel_url=https://lunchcard.co.za/portal&' +
      'notify_url=https://old.lunchcard.co.za/payment/default.aspx&';

    sPayVars =
      sPayVars +
      'name_first=' +
      this.generalService.parentDetails.name +
      '&name_last=' +
      this.generalService.parentDetails.surname +
      '&email_address=' +
      this.generalService.parentDetails.emailAddress +
      '&m_payment_id=' +
      this.apiServices.schoolDetails.id +
      ',' +
      this.generalService.parentDetails.id +
      ',3,0' +
      '&amount=' +
      this.apiServices.schoolDetails.monthlyFee +
      '&item_name=Lunchcard_monthly_payment' +
      '&item_description=Lunchcard_monthly_payment' +
      '&subscription_type=1' +
      '&billing_date=' +
      moment(new Date()).add(1, 'month').format('YYYY-MM-01') +
      '&recurring_amount=' +
      this.apiServices.schoolDetails.monthlyFee +
      '&frequency=3' +
      '&cycles=0';

    window.location.replace(sPayVars);
  }

  annualPayment() {
    let sPayVars =
      'https://www.payfast.co.za/eng/process?' +
      'merchant_id=10422300&' +
      'merchant_key=ol5gcdtri6odu&' +
      'return_url=https://lunchcard.co.za/service-fee-payment-successful&' +
      'cancel_url=https://lunchcard.co.za/portal&' +
      'notify_url=https://old.lunchcard.co.za/payment/default.aspx&';

    sPayVars =
      sPayVars +
      'name_first=' +
      this.generalService.parentDetails.name +
      '&name_last=' +
      this.generalService.parentDetails.surname +
      '&email_address=' +
      this.generalService.parentDetails.emailAddress +
      '&m_payment_id=' +
      this.apiServices.schoolDetails.id +
      ',' +
      this.generalService.parentDetails.id +
      ',1,0' +
      '&amount=' +
      this.apiServices.schoolDetails.annualFee +
      '&item_name=Lunchcard_annual_payment' +
      '&item_description=Lunchcard_annual_payment';
    window.location.replace(sPayVars);
  }
}
