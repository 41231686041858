<div class="top-container" [formGroup]="parentDetail">
  <div class="student-picture-container">
    <div class="student-picture-parent">
      <img
        src="../../../../assets/profile.png"
        class="student-picture"
        draggable="false"
      />
    </div>
    <div class="toggle-container">
      <mat-slide-toggle
        color="primary"
        formControlName="isLowBalanceNotifications"
        (change)="saveParent()"
      >
        Low Balance Notifications
      </mat-slide-toggle>
      <mat-slide-toggle
        color="primary"
        formControlName="isAutoTopup"
        (change)="saveParent()"
      >
        AutoTopup
      </mat-slide-toggle>
    </div>
  </div>
  <div class="details-container">
    <div class="details-heading-container">
      <div class="details-heading">Parent Details</div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text">
        <input
          class="text-field-details"
          type="text"
          autocomplete="off"
          formControlName="name"
          placeholder="Enter Name"
        />
      </div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text">
        <input
          class="text-field-details"
          type="text"
          autocomplete="off"
          formControlName="surname"
          placeholder="Enter Surname"
        />
      </div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text">
        <input
          class="text-field-details"
          type="text"
          autocomplete="off"
          formControlName="email"
          placeholder="Enter Email"
        />
      </div>
    </div>
    <div class="details-row-container">
      <div class="details-row-text">
        <input
          class="text-field-details"
          type="text"
          autocomplete="off"
          formControlName="phoneNumber"
          placeholder="Enter Phone Number"
        />
      </div>
    </div>

    <div class="details-row-container">
      <div class="details-row-text">
        <input
          class="text-field-details"
          type="text"
          autocomplete="off"
          formControlName="balanceNotificationAmount"
          placeholder="Enter Phone Number"
        />
      </div>
    </div>

    <div *ngIf="parentDetail.invalid && isSubmitted">
      <span class="hint-required">* All fileds are required</span>
    </div>

    <div class="save-button-large">
      <div class="details-row-edit-button" (click)="saveParent()">
        <mat-icon
          class="edit-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >save</mat-icon
        >
      </div>
    </div>
  </div>
</div>
