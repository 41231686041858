<div class="portal-heading">Bank deposit</div>
<div class="portal-subheading">
  Payments will be allocated within 1 to 2 business days.<br />These are the
  tuck-shop owner's banking details, funds are not held by Lunchcard.
</div>
<div class="details-container">
  <div class="detail-row">
    <div class="detail-row-title">Account name:</div>
    <div class="detail-row-text">{{bankDetails.accountName}}</div>
  </div>
  <div class="detail-row">
    <div class="detail-row-title">Account number:</div>
    <div class="detail-row-text">{{bankDetails.bankAccount}}</div>
  </div>
  <div class="detail-row">
    <div class="detail-row-title">Bank:</div>
    <div class="detail-row-text">{{bankDetails.bankName}}</div>
  </div>
  <div class="detail-row">
    <div class="detail-row-title">Branch code:</div>
    <div class="detail-row-text">{{bankDetails.bankCode}}</div>
  </div>
  <div class="detail-row">
    <div class="detail-row-title">Your payment reference:</div>
    <div class="detail-row-text">{{bankDetails.paymentReference}}</div>
  </div>
  <div class="footer-details-text">
    Please ensure that you use the correct reference for your deposit.
    <br />
    <strong>EFT Payments can be made from your internet banking app.</strong>
  </div>
  <div
    class="portal-button back-button"
    (click)="changeScreen('Select option')"
  >
    Back
  </div>
</div>
