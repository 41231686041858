import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ApiServicesService } from '../services/api-services.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { ParentDetails } from '../Models/ParentDetails';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Input() isMobile: number;
  public screenHeight: number;
  public screenWidth: number;
  public registrationForm: FormGroup;
  public isSubmitted = false;
  public screenHeightPercent = 1.2;
  public parentDetails: ParentDetails;
  public isError = false;
  public error: any;
  public isCurrentRequest = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private fb: FormBuilder,
    private apiServices: ApiServicesService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      createPassword: ['', [Validators.required, Validators.minLength(8)]],
      repeatPassword: ['', Validators.required],
      cardNumber: ['', Validators.required],
      pinNumber: ['', Validators.required],
      terms: ['', Validators.required],
    });
  }

  toggelAccept() {
    if (!this.registrationForm.get('terms').value) {
      this.registrationForm.get('terms').setValue(null);
    }
  }

  async submitRegistration() {
    if (this.isCurrentRequest) {
      return;
    }
    this.isCurrentRequest = true;

    this.isSubmitted = true;
    this.screenHeightPercent = 1.5;
    this.parentDetails = {
      id: null,
      name: this.registrationForm.get('name').value,
      surname: this.registrationForm.get('surname').value,
      emailAddress: this.registrationForm.get('email').value,
      password: this.registrationForm.get('createPassword').value,
      autoTopup: null,
      lowBalanceNotifications: null,
      balanceNotificationAmount: null,
      balance: null,
      loginResult: null,
      cellphoneNumber: null,
    };

    const payload = {
      name: this.registrationForm.get('name').value,
      surname: this.registrationForm.get('surname').value,
      emailAddress: this.registrationForm.get('email').value,
      password: this.registrationForm.get('createPassword').value,
      cardNumber: this.registrationForm.get('cardNumber').value,
      pinNumber: this.registrationForm.get('pinNumber').value,
    };

    if (!this.registrationForm.invalid) {
      const registrationResult = await this.apiServices.registration(payload);

      if (registrationResult === true) {
        this.apiServices.schoolDetails = await this.apiServices.getSchoolDetails();
        this.apiServices.getParentDetails();
        this.router.navigate(['/', 'portal']);
      } else {
        this.isError = true;
        this.error = registrationResult;
      }
    }
    this.isCurrentRequest = false;
  }
}
