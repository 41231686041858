import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ApiServicesService } from '../../services/api-services.service';
import { GeneralService } from '../../services/general.service';
import { StudentDetails } from 'src/app/Models/StudentDetails';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InactiveModalComponent } from '../inactive-modal/inactive-modal.component';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;

  students = [];
  currentStudent: StudentDetails;

  @ViewChild('paymentForm') paymentForm: ElementRef;
  webRequestForm: FormGroup;
  errorText: string = '';
  cardRequestForm: FormGroup;
  webpaymentData: any;
  webData = [];
  PAY_REQUEST_ID = '';
  CHECKSUM = '';

  constructor(
    private apiServices: ApiServicesService,
    public generalService: GeneralService,
    private fb: FormBuilder,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.cardRequestForm = this.fb.group({
      PAY_REQUEST_ID: ['', Validators.required],
      CHECKSUM: ['', Validators.required],
    });
    this.getStudents();
  }

  async selectStudent(student) {
    this.currentStudent = await this.apiServices.getStudentDetails(
      student.studentId
    );
    this.generalService.currentChildScreen = 'View student';
  }

  async restrictItems(student) {
    this.currentStudent = await this.apiServices.getStudentDetails(
      student.studentId
    );
    this.generalService.currentChildScreen = 'Student Restrictions';
  }

  async getStudents() {
    this.students = await this.apiServices.getStudents();

    this.students.forEach((student) => {
      if (student.status === 'Inactive') {
        this.openInactiveModal();
      }
    });
  }

  openInactiveModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '500px';
    dialogConfig.width = '700px';
    const modalDialog = this.matDialog.open(
      InactiveModalComponent,
      dialogConfig
    );
  }

  quickTopup(student) {
    this.generalService.currentStudent = student;
    this.generalService.currentChildScreen = 'Student selection';
    this.generalService.currentScreen = 'Quick topup';
  }

  async suspendStudent(student) {
    this.generalService
      .confirmDialog(
        'Confirm',
        'Are you sure you want to suspend ' +
          student.name +
          "'s account?" +
          '<br> (Suspending a student does not cancel the account)',
        'Yes'
      )
      .subscribe(async (response) => {
        if (response) {
          await this.apiServices.suspendStudentAccount(student.studentId);
          this.currentStudent = await this.apiServices.getStudentDetails(
            student.studentId
          );
        }
      });
  }

  async activateStudent(student) {
    this.generalService
      .confirmDialog(
        'Confirm',
        'Are you sure you want to re-activate ' + student.name + "'s account?",
        'Yes'
      )
      .subscribe(async (response) => {
        if (response) {
          await this.apiServices.activateStudentAccount(student.studentId);
          this.currentStudent = await this.apiServices.getStudentDetails(
            student.studentId
          );
        }
      });
  }

  async orderReplacementCard(student) {
    this.generalService
      .confirmDialog(
        'Order replacement card',
        'Ordering a replacement card will cost R65. <br/>Cards are delivered to the school within 2-3 working days.<br/><br/> Do you want to proceed with this order?',
        'Yes'
      )
      .subscribe(async (response) => {
        if (response) {
          const paymentDetails =
            await this.apiServices.initiateReplacementCardPayment(
              student.studentId
            );
          this.cardRequestForm.setValue({
            PAY_REQUEST_ID: [paymentDetails.paymentId],
            CHECKSUM: [paymentDetails.checksum],
          });
          this.paymentForm.nativeElement.submit();
        }
      });
  }

  viewReports(student) {
    this.generalService.currentChildScreen = 'Student selection';
    this.generalService.currentScreen = 'Reports';
  }
}
