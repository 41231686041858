<app-reports-student-selection
  *ngIf="generalService.currentChildScreen == 'Student selection'"
  [screenHeight]="screenHeight"
  [students]="students"
  (handleStudentClicked)="handleStudentClicked($event)"
  [isMobile]="isMobile"
>
</app-reports-student-selection>
<app-reports-view-data
  *ngIf="generalService.currentChildScreen == 'View data'"
  [screenHeight]="screenHeight"
  [selectedStudent]="selectedStudent"
  [students]="students"
  [currentMonthDate]="currentMonthDate"
  [pieChartLabels]="pieChartLabels"
  [pieChartData]="pieChartData"
  [barChartLabels]="barChartLabels"
  [barChartData]="barChartData"
  [monthPurchasesData]="monthPurchasesData"
  (handleStudentClicked)="handleStudentClicked($event)"
  (setCurrentMonth)="setCurrentMonth($event)"
  [isMobile]="isMobile"
>
</app-reports-view-data>
