import { Component, OnInit, HostListener } from '@angular/core';
import { ApiServicesService } from '../services/api-services.service';
import { Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  screenHeight: number;
  screenWidth: number;
  isMobile: boolean = false;
  isForgotPassword = false;
  showSchoolSelector = false;
  schoolOptions = [];
  selectedSchoolId = 0;
  public loginForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  public isError = false;
  public isPasswordRequestComplete = false;
  public error: string;
  public isButtonLoading: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 700) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  constructor(
    private apiServices: ApiServicesService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      emailAddress: ['', Validators.required],
      passWord: ['', Validators.required],
    });

    this.forgotPasswordForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
    });
  }

  async attemptLogin() {
    this.isButtonLoading = true;
    if (this.showSchoolSelector === false) {
      let loginResult = await this.apiServices.login(
        this.loginForm.get('emailAddress').value,
        this.loginForm.get('passWord').value
      );
      this.isButtonLoading = false;
      if (loginResult == true) {
        await this.getSchoolDetails();
        await this.apiServices.getParentDetails();
        this.router.navigate(['/', 'portal']);
      } else if (Array.isArray(loginResult)) {
        this.schoolOptions = loginResult;
        this.showSchoolSelector = true;
      } else {
        this.isError = true;
        this.error = loginResult;
      }
    } else {
      if (this.selectedSchoolId === 0) {
        this.isError = true;
        this.error = 'Please select a school from the list';
      } else {
        let loginResult = await this.apiServices.loginById(
          this.selectedSchoolId,
          this.loginForm.get('emailAddress').value,
          this.loginForm.get('passWord').value
        );
        if (loginResult == true) {
          await this.getSchoolDetails();
          await this.apiServices.getParentDetails();
          this.router.navigate(['/', 'portal']);
        } else {
          this.isError = true;
          this.error = loginResult;
        }
      }
      this.isButtonLoading = false;
    }
  }

  setSelectedSchool(schoolId) {
    this.selectedSchoolId = schoolId;
    console.log(schoolId);
  }

  async getSchoolDetails() {
    this.apiServices.schoolDetails = await this.apiServices.getSchoolDetails();
  }

  enterSubmit(event) {
    if (event.keyCode === 13) {
      this.attemptLogin();
    }
  }

  goBack(){
    this.isForgotPassword = false;
    this.isPasswordRequestComplete = false
  }

  async requestPassword() {
    this.isButtonLoading = true;

    if (!this.forgotPasswordForm.get('emailAddress').invalid) {
      let forgotPasswordResult = await this.apiServices.forgotPassword(
        this.forgotPasswordForm.get('emailAddress').value
      );

      if (forgotPasswordResult) {
        this.isPasswordRequestComplete = true;
      }      
    }
    
    this.isButtonLoading = false;
  }
}
