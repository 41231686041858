import { Component, OnInit, Input } from '@angular/core';
import { ApiServicesService } from 'src/app/services/api-services.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-deposit-funds',
  templateUrl: './deposit-funds.component.html',
  styleUrls: ['./deposit-funds.component.scss'],
})
export class DepositFundsComponent implements OnInit {
  @Input() screenHeight: number;
  @Input() screenWidth: number;
  @Input() isMobile: number;

  public isInstantPaymentAllowed = true;

  constructor(
    public generalService: GeneralService,
    private apiService: ApiServicesService
  ) {}

  ngOnInit(): void {
    if (this.screenWidth < 700) {
      this.screenHeight = this.screenHeight * 1.2;
    }
    if (this.screenHeight < 750) {
      // Smaller Laptop Screens
      this.screenHeight = this.screenHeight * 1.3;
    }
    this.checkIfInstantPayment();
  }

  async checkIfInstantPayment() {
    const result = await this.apiService.getSchoolDetails();
    if (!result.cardPayments) {
      this.isInstantPaymentAllowed = false;
    }
  }

  changeScreen(screen, childScreen) {
    this.generalService.currentScreen = screen;
    this.generalService.currentChildScreen = childScreen;
  }
}
