import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './website/home/home.component';
import { AboutComponent } from './website/about/about.component';
import { ContactComponent } from './website/contact/contact.component';
import { PortalComponent } from './portal/portal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MenubarComponent } from './menubar/menubar.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { WebsiteComponent } from './website/website.component';
import { HttpClientModule } from '@angular/common/http';
import { PortalMenuBarComponent } from './portal/portal-menu-bar/portal-menu-bar.component';
import { StudentsComponent } from './portal/students/students.component';
import { QuickTopupComponent } from './portal/quick-topup/quick-topup.component';
import { TopupComponent } from './portal/topup/topup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DepositFundsComponent } from './portal/deposit-funds/deposit-funds.component';
import { ReportsComponent } from './portal/reports/reports.component';
import { MealPlanningComponent } from './portal/meal-planning/meal-planning.component';
import { BankDepositComponent } from './portal/deposit-funds/bank-deposit/bank-deposit.component';
import { InstantDepositComponent } from './portal/deposit-funds/instant-deposit/instant-deposit.component';
import { SelectDepositOptionComponent } from './portal/deposit-funds/select-deposit-option/select-deposit-option.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ValidatePaymentComponent } from './portal/deposit-funds/validate-payment/validate-payment.component';
import { MealPlanningStudentSelectionComponent } from './portal/meal-planning/meal-planning-student-selection/meal-planning-student-selection.component';
import { MealPlanningIndexComponent } from './portal/meal-planning/meal-planning-index/meal-planning-index.component';
import { MealPlanningDateSelectionComponent } from './portal/meal-planning/meal-planning-date-selection/meal-planning-date-selection.component';
import { MealPlanningCategoriesComponent } from './portal/meal-planning/meal-planning-categories/meal-planning-categories.component';
import { MealPlanningItemSelectionComponent } from './portal/meal-planning/meal-planning-item-selection/meal-planning-item-selection.component';
import { MealPlanningOrderCheckoutComponent } from './portal/meal-planning/meal-planning-order-checkout/meal-planning-order-checkout.component';
import { MealPlanningOrderSummaryComponent } from './portal/meal-planning/meal-planning-order-summary/meal-planning-order-summary.component';
import { MealPlanningPaymentOptionsComponent } from './portal/meal-planning/meal-planning-payment-options/meal-planning-payment-options.component';
import { CompleteMealPlanComponent } from './portal/meal-planning/complete-meal-plan/complete-meal-plan.component';
import { ReportsStudentSelectionComponent } from './portal/reports/reports-student-selection/reports-student-selection.component';
import { ReportsViewDataComponent } from './portal/reports/reports-view-data/reports-view-data.component';
import { ViewStudentComponent } from './portal/students/view-student/view-student.component';
import { SetRestrictionsComponent } from './portal/students/set-restrictions/set-restrictions.component';
import { StudentSelectionComponent } from './portal/students/student-selection/student-selection.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { MobileHeaderBarComponent } from './mobile-header-bar/mobile-header-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { RegistrationComponent } from './registration/registration.component';
import { FaqComponent } from './website/faq/faq.component';
import { TermsandconditionsComponent } from './website/termsandconditions/termsandconditions.component';
import { PrivacyComponent } from './website/privacy/privacy.component';
import { ProfileComponent } from './portal/profile/profile.component';
import { AddStudentComponent } from './portal/students/add-student/add-student.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { ParentDetailsComponent } from './portal/profile/parent-details/parent-details.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReplacementCardOrderCompleteComponent } from './portal/students/replacement-card-order-complete/replacement-card-order-complete.component';
import { NewParentComponent } from './portal/profile/new-parent/new-parent.component';
import { InactiveModalComponent } from './portal/inactive-modal/inactive-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ServiceFeePaymentSuccessfulComponent } from './portal/service-fee-payment-successful/service-fee-payment-successful.component';
import { MealPlanningTimeSelectionComponent } from './portal/meal-planning/meal-planning-time-selection/meal-planning-time-selection.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    PortalComponent,
    MenubarComponent,
    LoginComponent,
    FooterComponent,
    WebsiteComponent,
    PortalMenuBarComponent,
    StudentsComponent,
    QuickTopupComponent,
    TopupComponent,
    NotificationPopupComponent,
    DepositFundsComponent,
    ReportsComponent,
    MealPlanningComponent,
    BankDepositComponent,
    InstantDepositComponent,
    SelectDepositOptionComponent,
    ValidatePaymentComponent,
    MealPlanningStudentSelectionComponent,
    MealPlanningIndexComponent,
    MealPlanningDateSelectionComponent,
    MealPlanningCategoriesComponent,
    MealPlanningItemSelectionComponent,
    MealPlanningOrderCheckoutComponent,
    MealPlanningOrderSummaryComponent,
    MealPlanningPaymentOptionsComponent,
    CompleteMealPlanComponent,
    ReportsStudentSelectionComponent,
    ReportsViewDataComponent,
    ViewStudentComponent,
    SetRestrictionsComponent,
    StudentSelectionComponent,
    ConfirmationPopupComponent,
    MobileHeaderBarComponent,
    RegistrationComponent,
    FaqComponent,
    TermsandconditionsComponent,
    PrivacyComponent,
    ProfileComponent,
    AddStudentComponent,
    ParentDetailsComponent,
    ReplacementCardOrderCompleteComponent,
    NewParentComponent,
    InactiveModalComponent,
    ServiceFeePaymentSuccessfulComponent,
    MealPlanningTimeSelectionComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    MatSlideToggleModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatSidenavModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxHotjarModule.forRoot('2684625'),
  ],
  providers: [DatePipe, AuthGuardService, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
